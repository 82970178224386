import { Component, OnInit } from '@angular/core';
import { DataLogger, DataLoggerControllerService } from '../rest';

@Component({
  selector: 'app-data-loggers',
  templateUrl: './data-loggers.component.html',
  styleUrls: ['./data-loggers.component.css']
})
export class DataLoggersComponent implements OnInit {
  dataLoggersArray: DataLogger[] = [];
  constructor(
    private dataLoggerControllerService: DataLoggerControllerService
  ) { }

  ngOnInit() {
    this.getDataloggers();
  }

  getDataloggers() {
    this.dataLoggerControllerService.dataLoggerControllerFind()
    .subscribe (results => {
      this.dataLoggersArray = results;
    });
  }

  delete(element) {
    this.dataLoggerControllerService.dataLoggerControllerDeleteById(element.id)
    .subscribe (results => {
      this.getDataloggers();
    });
    alert('delete'+element.id);
  // nothing so far!
  }
}
