import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { NotificationComponent } from './notification/notification.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private tokenSub: Subscription;
  openMenu=false;
  isAuthenticated = false;
  @ViewChild (NotificationComponent, {static: false}) notify;
  array : any[];
  
  constructor(private authService: AuthService) { }
  
  ngOnInit() {
    this.tokenSub = this.authService.token.subscribe(token => {
      this.isAuthenticated = !!token;
    });
  }
  ngAfterViewInit() {
    this.array = this.notify.eventsArray;
  }
  
  ngOnDestroy() {
    this.tokenSub.unsubscribe();
  }
  
  onLogout() {
    this.authService.logout();
  }

}
