import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.css']
})
export class AlertPopupComponent implements OnInit {
  slider: boolean;
  option: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data) {
      if (data[0] === 'slider') {
        this.slider = true;
        if (data[1]) {
          this.option = 'disable';
        } else {
          this.option = 'enable';
        }
      } else {
        this.slider = false;
      }
    }

  ngOnInit() {
  }
}
