import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { relative } from 'path';
import { SelectPopupComponent } from 'src/app/common/components/popups/select-popup/select-popup.component';
import { DeviceControllerService } from 'src/app/rest';
import { DataLogger, Device } from 'src/app/rest/model/models';

@Component({
  selector: 'app-device-management',
  templateUrl: './device-management.component.html',
  styleUrls: ['./device-management.component.css']
})
export class DeviceManagementComponent implements OnInit {
  deviceOptions = [
    {id: 0, name: 'Device Type', description: 'Manage Device Type'},
    {id: 1, name: 'Device', description: 'Manage Device'}
  ];
  selectedDatalogger = <DataLogger>{};
  loading: boolean = true;
  serviceError: boolean = false;
  gotDevices: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  openExplorer(id: number) {
    if (id === 0) {
      this.router.navigate(['./deviceType'], {relativeTo: this.route});
    }
    if (id === 1) {
      this.openDialog();
      // this.router.navigate(['./devices'], {relativeTo: this.route});
    }
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'device-management'};
    const dialogRef = this.dialog.open(SelectPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.selectedDatalogger = result;
      this.navToDevices(this.selectedDatalogger.id);
    });
   }

  navToDevices(id: number) {
    this.router.navigate(['./datalogger/' + id], { relativeTo: this.route });
  }
}
