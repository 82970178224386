import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataLogger, DataLoggerControllerService, AssetGroupControllerService, AssetGroup } from 'src/app/rest';

@Component({
  selector: 'app-select-popup',
  templateUrl: './select-popup.component.html',
  styleUrls: ['./select-popup.component.css']
})
export class SelectPopupComponent implements OnInit {
  description: string;
  form: FormGroup;
  loading: boolean = false;
  serviceError: boolean = false;
  assetGroups: AssetGroup[];
  datalogger: DataLogger;
  dataloggers: DataLogger[] = [];
  insertedData: string;
  selectedData: any;
  popUpData = [];
  title: string;


  constructor(
    private dataLoggerControllerService: DataLoggerControllerService,
    private authService: AuthService,
    private assetGroupControllerService: AssetGroupControllerService,
    public dialogRef: MatDialogRef<SelectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.insertedData = data.value;
    }

  ngOnInit() {
    if (this.insertedData === 'user-asset') {
      this.findAssetsFromAssetGroup();
      this.title = 'Asset Group';
    }else {
      this.findDataloggers();
      this.title = 'Datalogger';
    }
  }

  findDataloggers() {
    this.dataLoggerControllerService.dataLoggerControllerFind()
    .subscribe(
      value => {
        this.dataloggers.push(...value);
        this.popUpData = this.dataloggers;
        this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  findAssetsFromAssetGroup() {
    this.assetGroups = [];
    this.assetGroupControllerService.assetGroupControllerFindUserAssetGroups()
    .subscribe(
      value => {
        this.assetGroups.push(...value);
        this.popUpData = this.assetGroups;
        this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

}
