import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SavedDashboard } from 'src/app/common/components/buttons/add-new/add-new.component';
import { Asset, AssetControllerService, Channel, ChannelType, ChannelTypeExcludingNameDescriptionUnitChannelTypeDeviceTypeId, DashboardElement, DashboardElementControllerService, DashboardElementWithScope, DeviceControllerService,
  DeviceTypeChannelTypeControllerService,
  DeviceTypeControllerService, DeviceTypeExcludingModelDescriptionManufacturer, NewDashboardElement } from 'src/app/rest';

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.css']
})
export class DashboardListComponent implements OnInit {
  isAdmin = true;
  serviceError = false;
  allAssets: Asset[];
  userId: number;
  loading = true;
  allChannels: Channel[];
  allCategories: DeviceTypeExcludingModelDescriptionManufacturer[];
  allChannelCategories: string[];
  allDeviceTypes: string[];
  selectedAssetID: number;
  selectedCategory: number;
  createdDashboard: NewDashboardElement;
  allDashboards: DashboardElementWithScope[];
  type: string;
  constructor(
    private dashboardElementControllerService: DashboardElementControllerService,
    private router: ActivatedRoute,
    private assetControllerService: AssetControllerService,
    private deviceTypeControllerService: DeviceTypeControllerService,
    private deviceTypeChannelTypeControllerService: DeviceTypeChannelTypeControllerService,
    private deviceControllerService: DeviceControllerService,
    private authService: AuthService) { }

  ngOnInit() {
    this.userId = +this.router.snapshot.paramMap.get('userId');
    this.getAllAssets();
    this.getDashboard();
    this.getAllDeviceTypes();
  }

  getDashboard() {
    this.allDashboards = [];
    this.type = this.router.snapshot.paramMap.get('type');
    let assetId = -1;
    if (this.type === 'assetDashboard') {
      assetId = +this.router.snapshot.paramMap.get('assetId');
      this.selectedAssetID = assetId;
      this.getCategories();
    }
    this.dashboardElementControllerService.dashboardElementControllerFindByUserIdAssetIdLocation(assetId, this.userId, this.type)
    .subscribe(
      response => {
        this.allDashboards.push(...response);
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      });
  }

  getAllAssets() {
    this.allAssets = [];
    this.assetControllerService.assetControllerFindByUserId(this.userId)
    .subscribe(
      response => {
        this.allAssets.push(...response);
        this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  getChannelTypes(deviceTypeId) {
    this.allChannelCategories = [];
    this.deviceTypeChannelTypeControllerService.deviceTypeChannelTypeControllerDeviceTypeChannelCategories(deviceTypeId)
    .subscribe(
      response => {
        const allTypes = [];
        response.forEach(element => {
          if (element.category !== null && element.category !== '') {
              allTypes.push(element.category);
          }
        });
        this.allChannelCategories = allTypes.filter(this.onlyUnique);
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  getChannels() {
    this.allChannels = [];
    this.deviceControllerService.deviceControllerFindChannels(this.userId)
    .subscribe(
      response => {
        this.allChannels = response;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  getCategories() {
    this.allCategories = [];
    this.deviceTypeControllerService.deviceTypeControllerGetAssetDeviceCategories(this.selectedAssetID)
    .subscribe(
      response => {
        this.allCategories = response;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  getAllDeviceTypes() {
    this.allDeviceTypes = [];
    this.deviceTypeControllerService.deviceTypeControllerFind(0, 10)
    .subscribe(
      response => {
        // let allTypes = [];
        // response.forEach(element => {
        //   if (element.category !== null && element.category !== '') {
        //       allTypes.push(element.category);
        //   }
        // });
        // this.allDeviceTypes = allTypes.filter(this.onlyUnique);
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  saveDashboard(savedDashboard: SavedDashboard) {
    const currentLocation = this.router.snapshot.paramMap.get('type');
    if (this.type === 'assetDashboard') {
      this.createdDashboard = {assetId: this.selectedAssetID, userId: this.userId,
        deviceCategory: savedDashboard.deviceCategory.category, channelCategory: savedDashboard.channelCategory,
        elementType: savedDashboard.chartType, location: currentLocation , timeWindow: savedDashboard.timeWindow,
        operation: savedDashboard.operation, groupBy: savedDashboard.groupBy, xLabel: savedDashboard.xLabel,
        yLabel: savedDashboard.yLabel, title: savedDashboard.title};
    } else {
      this.createdDashboard = {assetId: savedDashboard.assetName.id, userId: this.userId,
        deviceCategory: savedDashboard.deviceCategory.category, channelCategory: savedDashboard.channelCategory,
        elementType: savedDashboard.chartType, location: currentLocation , timeWindow: savedDashboard.timeWindow,
        operation: savedDashboard.operation, groupBy: savedDashboard.groupBy, xLabel: savedDashboard.xLabel,
        yLabel: savedDashboard.yLabel, title: savedDashboard.title};
    }
    
    this.dashboardElementControllerService.dashboardElementControllerCreate(this.createdDashboard)
    .subscribe(
      response => {
        this.getDashboard();
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  getSelectedAsset(asset: Asset) {
    this.selectedAssetID = asset.id;
    this.getCategories();
  }

  getSelectedDeviceCategory(deviceCategory) {
    this.selectedCategory = deviceCategory;
    this.getChannelTypes(deviceCategory.id);
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  delete(selectedDashboard: DashboardElementWithScope) {
    this.dashboardElementControllerService.dashboardElementControllerDeleteById(selectedDashboard.dashboardElement.id)
    .subscribe(
      response => {
        this.getDashboard();
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  moveUp(selectedDashboard: DashboardElementWithScope) {
    this.dashboardElementControllerService.dashboardElementControllerMoveElementUp(selectedDashboard.dashboardElement.id)
    .subscribe(
      response => {
        this.getDashboard();
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  moveDown(selectedDashboard: DashboardElementWithScope) {
    this.dashboardElementControllerService.dashboardElementControllerMoveElementDown(selectedDashboard.dashboardElement.id)
    .subscribe(
      response => {
        this.getDashboard();
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }
}
