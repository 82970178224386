import { Component, OnInit } from '@angular/core';
import { Mission } from 'src/app/models/mission';
import { ThessalyAPIService } from 'src/app/services/thessaly-api.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css']
})
export class StatusComponent implements OnInit {
  errorMessage: string;
  currentMissionStatus: Mission = {MissionName: 'empty', MissionStatus: 'empty'};
  constructor(private thessalyApiService: ThessalyAPIService) { }

  ngOnInit() {
    // this.currentMission();
  }

  currentMission() {
    this.thessalyApiService.controllerGetMission('GetMissionStatus')
    .subscribe(
      response => {
        this.currentMissionStatus = response;
      },
      error => {
        this.errorMessage = error;
      }
    );
  }
}
