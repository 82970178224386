import { Component, OnInit } from '@angular/core';
import { ThessalyAPIService } from 'src/app/services/thessaly-api.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {

  tempMissionsList = ["Mission 1", "Mission 2", "Mission 3"];
  errorMessage: string;
  selectedFile64: any;
  missionName: string;
  ImageBaseData:string | ArrayBuffer=null;

  constructor(private thessalyApiService: ThessalyAPIService) { }

  ngOnInit() {
  }

  getAllMissions() {
    this.thessalyApiService.controllerGetMission('GetMissionsHistory')
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  handleFileInput(event) {
    var selectedFile = event.target.files[0];

    getBase64(selectedFile).
    then(data => {
        this.selectedFile64 = data;
    });

    function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }

  }

  loadMission() {
    this.thessalyApiService.controllerPostMission('LoadMission', {MissionName: this.missionName, MissionBin: this.selectedFile64})
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  getBase64Image(img) {
    // Create an empty canvas element
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    var dataURL = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}
