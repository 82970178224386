import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  UserExcludingIdEmailIsVirtualRolesSubscriptionReferenceCode,
  UserExcludingIdUsernameIsVirtualRolesSubscriptionReferenceCode,
  Token,
  User,
  UserControllerService
} from '../rest';
import { Router } from '@angular/router';

import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthService {
  token = new BehaviorSubject<Token>(null);

  constructor(
    private http: HttpClient,
    private userControllerService: UserControllerService,
    private router: Router
  ) { }

  autoLogin() {
    const _token = JSON.parse(localStorage.getItem('token'));
    //console.log(_token);

    if (!_token) return;

    this.token.next(_token);
  }

  login(credentials: UserExcludingIdEmailIsVirtualRolesSubscriptionReferenceCode) {
    return this.userControllerService
      .userControllerLogin(credentials)
      .pipe(
        catchError(errorRes => {
          let errorMessage = 'An error occured!';

          if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
            return throwError(errorMessage);
          }

          switch (parseInt(errorRes.error.error.statusCode)) {
            case 401:
              errorMessage = 'Invalid Login!';
          }
          return throwError(errorMessage);
        }),
        tap(token => {
          this.token.next(token);
          localStorage.setItem('token', JSON.stringify(token));
        })
      );
  }

  logout() {
    this.token.next(null);
    this.router.navigate(["/login"]);
    localStorage.removeItem('token');
  }

  register(refCode: string, newUser: UserExcludingIdUsernameIsVirtualRolesSubscriptionReferenceCode) {
    return this.userControllerService
      .userControllerCreate(refCode, newUser)
      .pipe(catchError(errorRes => {
        let errorMessage = 'An error occured!';

        if (!errorRes.error || !errorRes.error.error) {
          return throwError(errorMessage);
        }

        switch (parseInt(errorRes.error.error.statusCode)) {
          case 422:
            errorMessage = 'User already exists';
        }
        return throwError(errorMessage);
      }));
  }
}
