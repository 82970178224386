import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ThessalyAPIService } from 'src/app/services/thessaly-api.service';
import { DialogPopupComponent } from './dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.css']
})
export class MissionComponent implements OnInit {
  @Input() missionTitle = 'Mission 0';
  errorMessage: string;
  totalSeconds: number;
  constructor(
    private dialog: MatDialog,
    private thessalyApiService: ThessalyAPIService) { }

  ngOnInit() {
    //mia fora na trexei to call oxi polles 
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '250px';
    dialogConfig.height = '250px';
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'den lew'};
    const dialogRef = this.dialog.open(DialogPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        if (result[1] === 'Minute(s)') {
          this.totalSeconds = result[0] * 60;
        } else if (result[1] === 'Hour(s)') {
          this.totalSeconds = result[0] * 3600;
        } else {
          this.totalSeconds = result[0];
        }
      }
    });
   }

   setPeriodForDefaultMission() {
    //this.thessalyApiService.controllerPostMission('SetPeriodForDefaultMission', {Period: this.totalSeconds})
    this.thessalyApiService.controllerPostMission('SetPeriodForDefaultMission', {Period: 500})
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  setMissionDefault(missionName: string) {
    this.thessalyApiService.controllerPostMission('SetDefaultMission', {MissionName: missionName})
    .subscribe(
      response => {
        this.setPeriodForDefaultMission();
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  removeMission(missionName: string) {
    this.thessalyApiService.controllerPostMission('RemoveMission', {MissionName: missionName})
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }
}
