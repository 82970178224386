import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DroneStatus } from 'src/app/models/droneStatus';
import { LocationService } from 'src/app/services/location-service';
import { ThessalyAPIService } from 'src/app/services/thessaly-api.service';

@Component({
  selector: 'app-drone-controller',
  templateUrl: './drone-controller.component.html',
  styleUrls: ['./drone-controller.component.css']
})
export class DroneControllerComponent implements OnInit {
  errorMessage: string;
  imageUrl = 'assets/images/';
  droneStatusResponse: DroneStatus;
  fightStatusImageUrl = 'assets/images/loading.svg';
  vehicleModeImageUrl = 'assets/images/loading.svg';
  batteryStatus = 50;

  constructor(
    private thessalyApiService: ThessalyAPIService,
    private routeActive: ActivatedRoute,
    private router: Router,
    private locationService: LocationService) { }

  ngOnInit() {
    this.fightStatusImageUrl = 'assets/images/landing.svg';
    this.vehicleModeImageUrl = 'assets/images/pause.svg';
    // this.getDroneSatus();
  }

  getDroneSatus() {
    this.thessalyApiService.controllerDrone()
    .subscribe(
      response => {
        this.droneStatusResponse = response;
        this.locationService.coordinates(
          this.droneStatusResponse.CurrentPosition.Latitude, this.droneStatusResponse.CurrentPosition.Longitude,
          this.droneStatusResponse.HomePosition.Latitude, this.droneStatusResponse.HomePosition.Longitude);
        // console.log(response);
        // this.batteryStatus = parseInt(response.BatteryLevel, 10);
        // this.getImages();
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  getImages() {
    this.fightStatusImageUrl = this.imageUrl + this.droneStatusResponse.FlightStatus;
    this.vehicleModeImageUrl = this.imageUrl + this.droneStatusResponse.VehicleMode;
  }

  openDetails() {
    this.router.navigate(['../droneMap'], {relativeTo: this.routeActive});
  }
}
