import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataLogger, DataLoggerControllerService, DataLoggerExcludingIdAuthTokenConfigUserId, RequestDataLoggerSetupCsv2Data, RequestDataLoggerSetupCsv2DataHeaderLocation, RequestDataLoggerSetupCsv2DataHeaderLocationKeysArray, RequestDataLoggerSetupCsv2DataHeaderLocationKeysNumber, RequestDataLoggerSetupCsv2DataHeaderLocationKeysStrArray, RequestDataLoggerSetupCsv2DataValueLocation, RequestDataLoggerSetupCsv2ExcludingFileFormat, RequestDataLoggerSetupCsv2LocationSpec, RequestDataLoggerSetupCsv2TimeData, ResponseDataLoggerSetupStep2 } from 'src/app/rest';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import $ from "jquery";

@Component({
  selector: 'app-new-datalogger',
  templateUrl: './new-datalogger.component.html',
  styleUrls: ['./new-datalogger.component.css'],
  providers: [NgbModalConfig, NgbModal, NgbActiveModal]
})
export class NewDataloggerComponent implements OnInit {
  @Output() done: EventEmitter<any> = new EventEmitter();
  description: string;
  fileFormat: string;
  username: string;
  password: string;
  autoInit: true;
  dataloggerID: number;
  dataCompressed: true;
  connectionType: string;
  manufacturer: string;
  formdata = new FormData();
  delimiter: string;
  decimalPoint: string;
  timezone: RequestDataLoggerSetupCsv2LocationSpec;
  format: RequestDataLoggerSetupCsv2LocationSpec;
  timeData: RequestDataLoggerSetupCsv2TimeData ;
  channelName: RequestDataLoggerSetupCsv2DataHeaderLocationKeysStrArray ;
  channelUnit: RequestDataLoggerSetupCsv2DataHeaderLocationKeysStrArray;
  period: RequestDataLoggerSetupCsv2DataHeaderLocationKeysNumber;
  model: RequestDataLoggerSetupCsv2DataHeaderLocationKeysArray ;
  serialNo: RequestDataLoggerSetupCsv2DataHeaderLocationKeysArray ;
  header: RequestDataLoggerSetupCsv2DataHeaderLocation;
  value: RequestDataLoggerSetupCsv2DataValueLocation ;
  data: RequestDataLoggerSetupCsv2Data;
  homeUrl = 'https://dev.k-ren.gr:3000/api/data-logger/setup-ftp/step1/';
  uploadDone = false;
  manualUnitName: string;
  manualChannelName: string;

  dataloggerCreated: DataLogger;
  dataloggerToken: string;
  dataloggerOptions: DataLoggerExcludingIdAuthTokenConfigUserId;
  configDatalogger: RequestDataLoggerSetupCsv2ExcludingFileFormat;
  dataloggerDevices: ResponseDataLoggerSetupStep2;
  constructor(
    private dataLoggerControllerService: DataLoggerControllerService,
    private config: NgbModalConfig,
    private modalService: NgbModal,
    private modalActiveService: NgbActiveModal
  ) { }

  ngOnInit() {
    this.delimiter = '';
    this.decimalPoint = '';
    this.timezone = {column: 0, row: 0, setManually: true, manualValue: 'string'};
    this.format = {column: 0, row: 0, setManually: true, manualValue: 'string'};
    this.timeData = {column: 0, firstItemRow: 0, nextItemIteration: 0, timezone: this.timezone, format: this.format};
    this.channelName = {firstItemRow: 0, setManually: true, manualValue: ['string']};
    this.channelUnit = {firstItemRow: 0, setManually: true, manualValue: ['string']};
    this.period = {firstItemRow: 0, setManually: true, manualValue: 0};;
    this.model = {firstItemRow: 0, setManually: true, manualValue: 'string'};
    this.serialNo = {firstItemRow: 0, setManually: true, manualValue: 'string'};
    this.header = {firstItemCol: 0, nextItemIteration: 0, model: this.model, serialNo: this.serialNo,
      channelName: this.channelName, channelUnit: this.channelUnit, period: this.period};
    this.value = {firstItemRow: 0, nextItemIteration: 0};
    this.data = {header: this.header, value: this.value};
  }

  save(selectedModal) {
    this.dataloggerOptions = {
      description: this.description, fileFormat: this.fileFormat,
      username: this.username, passwd: this.password,
      dataCompressed: true, autoInit: true,
      connectionType: this.connectionType
    };
    this.dataLoggerControllerService.dataLoggerControllerCreate(this.dataloggerOptions)
    .subscribe(
      response => {
        this.dataloggerCreated = response;
        this.dataloggerID = this.dataloggerCreated.id;
        this.dataloggerToken = this.dataloggerCreated.authToken;
        this.upload();
        this.modalService.open(selectedModal);
        // this.sendCSVstep1();
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }

  onConnectionTypeChange(value) {
    this.connectionType = value;
  }

  onFileFormatChange(value) {
    this.fileFormat = value;
  }

  uploadCSVFile(event) {
    let selectedFile = event.target.files[0];
    this.formdata.append("file", selectedFile);
  }

  async upload() {
    this.homeUrl = this.homeUrl + '/' + this.dataloggerID + '/' + this.dataloggerToken;
    $.ajax(this.homeUrl, {"async": true,
    "crossDomain": true,
    "method": "POST",
    "processData": false,
    "contentType": false,
    "mimeType": "multipart/form-data",
    "data": this.formdata}).done(function (response) {
      // this.sendCSVstep1();
    });
  }

  // sendCSVstep1() {
  //   this.dataLoggerControllerService.dataLoggerControllerSetupFromFtp1(this.dataloggerID, this.dataloggerToken)
  //   .subscribe(
  //     response => {
  //       this.sendCSVstep2();
  //     },
  //     errorMessage => {
  //       console.log(errorMessage);
  //   });
  // }

  sendCSVstep2() {
    this.dataLoggerControllerService.dataLoggerControllerSetupFromFtpCsv2(this.dataloggerID, this.configDatalogger)
    .subscribe(
      response => {
        this.dataloggerDevices = response;
        this.sendCSVstep3();
      },
      errorMessage => {
        console.log(errorMessage);
    });
  }

  sendCSVstep3() {
    let idManufacturer = 0;
    this.dataloggerDevices.deviceTypes.forEach(deviceType => {
      deviceType.manufacturer = 'testingManufacturer' + idManufacturer;
      idManufacturer = idManufacturer + 1;
    });
    delete this.dataloggerDevices.sample;
    this.dataLoggerControllerService.dataLoggerControllerSetupFromFtp3(this.dataloggerID, this.dataloggerDevices)
    .subscribe(
      response => {
        this.dataloggerDevices = response;
        this.done.emit();
      },
      errorMessage => {
        console.log(errorMessage);
    });
  }

  openNewModal(selectedModal) {
    this.modalService.dismissAll();
    this.modalService.open(selectedModal);
  }

  endSetupCSV() {
    if (this.data.header.channelName.setManually) {
      this.data.header.channelName.manualValue = this.manualChannelName.split(',');
    } else {
      this.data.header.channelName.manualValue = [];
    }

    if (this.data.header.channelUnit.setManually) {
      this.data.header.channelUnit.manualValue = this.manualUnitName.split(',');
    } else {
      this.data.header.channelUnit.manualValue = [];
    }
    this.configDatalogger = {delimiter: this.delimiter, decimalPoint: this.decimalPoint, timeData: this.timeData, data: this.data, confirm: true};
    this.sendCSVstep2();
  }
}
