import { Component, OnInit } from '@angular/core';
import { Mission } from 'src/app/models/mission';
import { ThessalyAPIService } from 'src/app/services/thessaly-api.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.css']
})
export class ControlsComponent implements OnInit {

  tempMissionList = ['Mission 1', 'Mission 2', 'Mission 3'];
  missionList: Mission;
  imageList = [
    'play_arrow',
    'pause'];
  counter = 0;
  selectedImage = this.imageList[0];
  selectedMission: string;
  state = 'start';
  errorMessage: string;
  color = 'green';
  currentMissionStatus: Mission = {MissionName: 'empty', MissionStatus: 'empty'};

  constructor(private thessalyApiService: ThessalyAPIService) { }

  ngOnInit() {
    // this.missionName();
    // this.startMission();
  }

  onClickStart() {
    // if (this.counter === 0) {
    //   this.startMission();
    // } else if (this.counter % 2 === 0) {
    //   this.resumeMission();
    // } else {
    //   this.suspendMission();
    // }
    this.counter += 1;
    this.selectedImage = this.imageList[this.counter % 2];
    this.color = 'yellow';
  }

  onClickStop() {
    this.counter = 0;
    this.color = 'green';
    this.selectedImage = this.imageList[0];
    // this.abortMission();
  }

  missionName() {
    this.thessalyApiService.controllerGetMission('GetMissionStatus')
    .subscribe(
      response => {
        this.missionList = response;
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  currentMission() {
    this.thessalyApiService.controllerGetMission('GetMissionStatus')
    .subscribe(
      response => {
        this.currentMissionStatus = response;
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  startMission() {
    this.thessalyApiService.controllerPostMission('StartMission', {MissionName: 'peratis_all'})
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  suspendMission() {
    this.thessalyApiService.controllerPostMission('SuspendMission', {})
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  resumeMission() {
    this.thessalyApiService.controllerPostMission('ResumeMission', {})
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  abortMission() {
    this.thessalyApiService.controllerPostMission('AbortMission', {})
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }
}
