import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { AssetGroupsComponent } from 'src/app/asset-groups/asset-groups.component';
import { AlertPopupComponent } from '../popups/alert-popup/alert-popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { isAdminService } from 'src/app/auth/isAdmin.service';


@Component({
  selector: 'app-media-object-round',
  templateUrl: './media-object-round.component.html',
  styleUrls: ['./media-object-round.component.css']
})
export class MediaObjectRoundComponent implements OnInit, OnChanges {
  @Output() deleteThis = new EventEmitter<number>();
  @Output() editThis = new EventEmitter<number>();
  @Output() permitions = new EventEmitter<number>();
  @Output() assetDashboards = new EventEmitter<number>();
  @Output() homeDashboards = new EventEmitter<number>();
  @Output() selectedDeviceTypes = new EventEmitter<number>();
  @Output() selectedDevice = new EventEmitter<number>();
  @Output() selectedChannelOption = new EventEmitter<{sliced: boolean, channelId: number, activateAll: boolean}>();
  @Output() userId = new EventEmitter<number>();
  @Output() assetId = new EventEmitter<number>();
  // @Output() moveUp = new EventEmitter<number>();
  // @Output() moveDown = new EventEmitter<number>();
  @Input() fromComponent: string;
  @Input() isAdmin: boolean = true;
  @Input() monitor: boolean;
  @Input() element;
  // isAdmin = true;
  slideValue: boolean = false;
  activateAll: boolean = false;
  // @Input() element: {id: number, name: string, description: string} = {
  //     id: 0,
  //     name: 'Object Name',
  //     description: 'Object Description'
  // };

  constructor(
    private route: ActivatedRoute,
    private isAdminService: isAdminService,
    private router: Router,
    private dialog: MatDialog) { }

  ngOnInit() {
    // this.isAdmin = this.isAdminService.isAdmin();
    this.slideValue = this.monitor;
  }

  ngOnChanges() {
    this.slideValue = this.monitor;
  }

  callParentEdit(element): void {
    this.editThis.next(element);
  }

  callHomeDashboard(userId: number) {
    this.homeDashboards.next(userId);
  }

  callAssetDashboard(userId: number) {
    this.assetDashboards.next(userId);
  }

  goToChannelTypes(deviceType) {
    this.selectedDeviceTypes.next(deviceType);
  }

  navToChannels(device) {
    this.selectedDevice.next(device);
  }

  callParentDelete(element): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = ['no slider', this.slideValue];
    const dialogRef = this.dialog.open(AlertPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.deleteThis.next(element);
      }
    });
  }

  callParentPermitions(element) {
    this.permitions.next(element);
  }

  goToDevices(element) {
    this.router.navigate(['./asset/' + element.id + '/devices'], { relativeTo: this.route });
  }

  setUserId(id: number) {
    this.userId.next(id);
  }

  setAssetId(id: number) {
    this.assetId.next(id);
  }

  // callMoveUp(id: number) {
  //   this.moveUp.next(id);
  // }

  // callMoveDown(id: number) {
  //   this.moveDown.next(id);
  // }

  changeSlider(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = ['slider', this.slideValue];
    const dialogRef = this.dialog.open(AlertPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.activateAll = true;
      }
      this.slideValue = !this.slideValue;
      this.selectedChannelOption.next({sliced: this.slideValue, channelId: id, activateAll: this.activateAll});
    });
    this.activateAll = false;
  }
}
