import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ManagementPopupComponent } from 'src/app/common/components/popups/management-popup/management-popup.component';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {
  arrayList = [
    {id: 1, name: 'Asset Groups', description: 'Manage Asset Groups'},
    {id: 2, name: 'Assets', description: 'Manage Assets'},
    {id: 3, name: 'Data Loggers', description: 'Manage Data Loggers'},
    {id: 4, name: 'Users', description: 'Manage Users'},
    {id: 5, name: 'Monitoring', description: 'Control Monitoring'},
    {id: 6, name: 'Devices', description: 'Manage Device Parameters'}
  ];
  constructor(private router: Router, private dialog: MatDialog) { }

  ngOnInit() {
  }

  openExplorer(id: number) {
    if (id === 1) {
      this.router.navigate(['home']);
    }
    if (id === 2) {
      this.openDialog();
    }
    if (id === 3) {
      this.router.navigate(['dataLoggers']);
    }
    if (id === 4) {
      this.router.navigate(['users']);
    }
    if (id === 5) {
      this.router.navigate(['monitoring']);
    }
    if (id === 6) {
      this.router.navigate(['deviceManagement']);
    }
  }
  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.width = '250px';
    dialogConfig.height = '200px';
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'den lew'};
    this.dialog.open(ManagementPopupComponent, dialogConfig);
   }
}
