import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import {isAdminService} from '../auth/isAdmin.service';
import { DashboardElementControllerService, DashboardElementWithScope } from '../rest';

export interface INavLink {
  id: number;
  name: string;
  description: string;
}
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  isAdmin: boolean = false;

  selectedNavLink: INavLink;
  subscription: Subscription;
  isEmpty: boolean = false;
  userID: number;
  loading = true;
  serviceError = false;
  allDashboards: DashboardElementWithScope[];

  navLinks: Array<INavLink> = [
    { name : 'Area Chart', id: 1, description: 'Manage Area Chart'},
    { name : 'Bubble Chart', id: 2, description: 'Manage Bubble Chart'},
    { name : 'Candlestick Chart', id: 3, description: 'Manage Candlestick Chart'},
    { name : 'Combo Chart', id: 4, description: 'Manage Combo Chart'},
    { name : 'Geo Chart', id: 5, description: 'Manage Geo Chart'},
    { name : 'Heat Map', id: 6, description: 'Manage Heat Map'},
    { name : 'Histogram', id: 7, description: 'Manage Histogram'},
    { name : 'Line Chart', id: 8, description: 'Manage Line Chart'},
    { name : 'Pie Chart', id: 9, description: 'Manage Pie Chart'},
    { name : 'Sankey Map', id: 10, description: 'Manage Sankey Map'},
    { name : 'Scatter Chart', id: 11, description: 'Manage Scatter Chart'},
    { name : 'Timeline', id: 12, description: 'Manage Timeline'}
  ];
  constructor(
    private dashboardElementControllerService: DashboardElementControllerService,
    private router: ActivatedRoute,
    private isAdminService: isAdminService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isAdmin = this.isAdminService.isAdmin();
    this.getIdFromToken();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getIdFromToken() {
    const token: Token = JSON.parse(localStorage.getItem('token')).token;
    const decoded = jwt_decode(token);
    this.userID = decoded.id;
    this.getDashboard();
  }

  getDashboard() {
    this.allDashboards = [];
    this.subscription = this.dashboardElementControllerService.dashboardElementControllerFindByUserIdAssetIdLocation(-1, this.userID, 'mainDashboard')
    .subscribe(
      response => {
        this.allDashboards.push(...response);
        this.isEmpty = (this.allDashboards.length === 0) ? true: false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      });
  }

  moveUp(selectedDashboard: DashboardElementWithScope) {
    this.dashboardElementControllerService.dashboardElementControllerMoveElementUp(selectedDashboard.dashboardElement.id)
    .subscribe(
      response => {
        this.getDashboard();
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  moveDown(selectedDashboard: DashboardElementWithScope) {
    this.dashboardElementControllerService.dashboardElementControllerMoveElementDown(selectedDashboard.dashboardElement.id)
    .subscribe(
      response => {
        this.getDashboard();
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }
}
