import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserExcludingIdUsernameIsVirtualRolesSubscriptionReferenceCode, UserExcludingIdEmailIsVirtualRolesSubscriptionReferenceCode } from '../rest';
import { AuthService } from './auth.service'
import { isAdminService } from 'src/app/auth/isAdmin.service'
import { Token } from '@angular/compiler/src/ml_parser/lexer';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  error: string = null;
  isLoading = false;
  user = <UserExcludingIdUsernameIsVirtualRolesSubscriptionReferenceCode>{};
  credentials = <UserExcludingIdEmailIsVirtualRolesSubscriptionReferenceCode>{};
  constructor(
    private router: Router,
    private authService: AuthService,
    private isAdminService: isAdminService
  ) { }

  ngOnInit() {
    try {
      const token: Token = JSON.parse(localStorage.getItem('token')).token;
      alert('You are already logged in. If you want to log in with another user please logout first');
      this.router.navigate(['/home']);
    } catch (error) {
    }
  }

  onSubmit(form: NgForm) {
    this.credentials.username = this.user.email;
    this.credentials.passwd = this.user.passwd;

    this.authService.login(this.credentials).subscribe(
      response => {
        this.isLoading = false;
        if(this.isAdminService.isAdmin()) {
          this.router.navigate(['/adminDashboard']);
        } else {
          this.router.navigate(['/home']);
        }
      },
      errorMessage => {
        console.log(errorMessage);
        this.error = errorMessage;
        this.authService.logout();
        this.isLoading = false;
      });
  }
}
