import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notify',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  eventsArray: any[] = [1,2,3,4,5,6];
  constructor() { }

  ngOnInit() {
  }

}
