import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-media-object-round-pill',
  templateUrl: './media-object-round-pill.component.html',
  styleUrls: ['./media-object-round-pill.component.css']
})
export class MediaObjectRoundPillComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
