import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { EditPopUpComponent } from 'src/app/common/components/popups/edit-pop-up/edit-pop-up.component';
import { DeviceType, DeviceTypeControllerService, DeviceTypeExcludingIdModelManufacturer } from 'src/app/rest';

@Component({
  selector: 'app-device-type',
  templateUrl: './device-type.component.html',
  styleUrls: ['./device-type.component.css']
})
export class DeviceTypeComponent implements OnInit {
  loading = true;
  serviceError = false;
  allDeviceTypes: DeviceType[];
  displayedDeviceTypes: {id: number, name: string, description: string}[] = [];
  constructor(
    private deviceTypeControllerService: DeviceTypeControllerService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.getDeviceTypes();
  }

  getDeviceTypes() {
    this.deviceTypeControllerService.deviceTypeControllerFind(0, 10)
    .subscribe(
      response => {
        this.allDeviceTypes = response;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      });
  }

  edit(editDeviceType: DeviceType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'Device Type', DeviceType: editDeviceType};
    const dialogRef = this.dialog.open(EditPopUpComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        this.postChanges(result);
      }
    });
  }

  postChanges(editDeviceType) {
    const changedValues: DeviceTypeExcludingIdModelManufacturer = {
      description: editDeviceType.description, category: editDeviceType.name};
    this.deviceTypeControllerService.deviceTypeControllerUpdateById(editDeviceType.DeviceType.id, changedValues)
    .subscribe(
      response => {
        this.getDeviceTypes();
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }

  delete(element) {
    alert('DELETED!' + element.id);
  }

  goToChannelTypes(deviceType) {
    this.router.navigate(['./' + deviceType.id], { relativeTo: this.route });
  }
}
