import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AssetGroupControllerService, AssetGroup, AssetGroupExcludingIdUserId } from '../rest';
import { AuthService } from '../auth/auth.service';
import { EditPopUpComponent } from '../common/components/popups/edit-pop-up/edit-pop-up.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { SavedElement } from '../common/components/buttons/add-new/add-new.component';
import { Subscription } from 'rxjs';
import { isAdminService } from 'src/app/auth/isAdmin.service';

@Component({
  selector: 'app-asset-groups',
  templateUrl: './asset-groups.component.html',
  styleUrls: ['./asset-groups.component.css']
})
export class AssetGroupsComponent implements OnInit {
  isAdmin = true;
  assetGroups: AssetGroup[];
  subscription: Subscription;
  serviceError = false;
  loading = false;
  newAssetGroup = <AssetGroupExcludingIdUserId>{};
  assetGroupName: string;
  assetGroupDescription: string;
  constructor(
    private router: Router,
    private assetGroupControllerService: AssetGroupControllerService,
    private dialog: MatDialog,
    private authService: AuthService,
    private isAdminService: isAdminService
  ) { }

  ngOnInit() {
    this.isAdmin = this.isAdminService.isAdmin();
    this.makeCall();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  makeCall() {
    this.loading = true;
    this.assetGroups = [];
    this.subscription = this.assetGroupControllerService.assetGroupControllerFindUserAssetGroups()
    .subscribe(
      value => {
        // if (value.length === 1) {
        //   this.router.navigate(['assetGroup/' + value[0].id]);
        // }
        this.assetGroups.push(...value);
        this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  onAssetGroupSelect(id: number) {
    this.router.navigate(['assetGroup/' + id]);
  }

  saveAssetGroup(savedElement: SavedElement) {
    this.newAssetGroup.description = savedElement.description;
    this.newAssetGroup.name = savedElement.name;
    this.subscription = this.assetGroupControllerService.assetGroupControllerCreateAssetGroup(this.newAssetGroup)
    .subscribe(
      response => {
        this.makeCall();
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }

  delete(element) {
    this.subscription = this.assetGroupControllerService.assetGroupControllerDeleteById(element.id)
    .subscribe(
      response => {
        this.makeCall();
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }

  edit(editAssetGroup: AssetGroup) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'Asset Group', name: editAssetGroup.name, description: editAssetGroup.description};
    const dialogRef = this.dialog.open(EditPopUpComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        editAssetGroup.description = result.description;
        editAssetGroup.name = result.name;
        this.postChanges(editAssetGroup);
      }
    });
  }

  postChanges(changedAssetGroup: AssetGroup) {
    const changedValues: AssetGroupExcludingIdUserId = {name: changedAssetGroup.name, description: changedAssetGroup.description};
    this.subscription = this.assetGroupControllerService.assetGroupControllerUpdateById(changedAssetGroup.id, changedValues)
    .subscribe(
      response => {
        //this.makeCallForAssets(this.route.snapshot.params['id']);
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }
}
