/**
 * Atman IoT
 * Atman IoT Server
 *
 * The version of the OpenAPI document: 3.0.2
 * Contact: k.khoury@k-ren.gr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { DataLogger } from '../model/models';
import { DataLoggerExcludingIdAuthTokenConfigUserId } from '../model/models';
import { RequestDataLoggerSetupCsv2ExcludingFileFormat } from '../model/models';
import { RequestDataLoggerSetupXml2ExcludingFileFormat } from '../model/models';
import { ResponseDataLoggerSetupStep2 } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DataLoggerControllerService {

    protected basePath = 'https://atman-iot.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Used for creating a new &lt;b&gt;DataLogger&lt;/b&gt; by and authorized &lt;b&gt;User&lt;/b&gt;, identified by the provided &lt;i&gt;token&lt;/i&gt;. &lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-usercontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;UserController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param dataLoggerExcludingIdAuthTokenConfigUserId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerCreate(dataLoggerExcludingIdAuthTokenConfigUserId?: DataLoggerExcludingIdAuthTokenConfigUserId, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DataLogger>;
    public dataLoggerControllerCreate(dataLoggerExcludingIdAuthTokenConfigUserId?: DataLoggerExcludingIdAuthTokenConfigUserId, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DataLogger>>;
    public dataLoggerControllerCreate(dataLoggerExcludingIdAuthTokenConfigUserId?: DataLoggerExcludingIdAuthTokenConfigUserId, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DataLogger>>;
    public dataLoggerControllerCreate(dataLoggerExcludingIdAuthTokenConfigUserId?: DataLoggerExcludingIdAuthTokenConfigUserId, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<DataLogger>(`${this.configuration.basePath}/data-logger`,
            dataLoggerExcludingIdAuthTokenConfigUserId,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a&lt;b&gt; DataLogger&lt;/b&gt; identified by &lt;i&gt;id&lt;/i&gt;, owned by a &lt;b&gt;User&lt;/b&gt; identified by the provided &lt;i&gt;token.&lt;/i&gt;&lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-usercontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;UserController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerDeleteById(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dataLoggerControllerDeleteById(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dataLoggerControllerDeleteById(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dataLoggerControllerDeleteById(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataLoggerControllerDeleteById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/data-logger/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an array of &lt;b&gt;DataLoggers&lt;/b&gt; owned by a &lt;b&gt;User&lt;/b&gt; identified by the provided &lt;i&gt;token.&lt;/i&gt;&lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-usercontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;UserController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerFind(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<DataLogger>>;
    public dataLoggerControllerFind(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<DataLogger>>>;
    public dataLoggerControllerFind(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<DataLogger>>>;
    public dataLoggerControllerFind(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<DataLogger>>(`${this.configuration.basePath}/data-loggers`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Step 1 of setup process of &lt;b&gt;DataLoggers&lt;/b&gt; sending data via FTP. It is used for sending a sample uncompressed &lt;i&gt;file&lt;/i&gt; containing raw data, as it was generated by the &lt;b&gt;DataLogger&lt;/b&gt; (hardware) identified by &lt;i&gt;dataLoggerToken&lt;/i&gt;.&lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param id 
     * @param dataLoggerToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerSetupFromFtp1(id: number, dataLoggerToken: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dataLoggerControllerSetupFromFtp1(id: number, dataLoggerToken: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dataLoggerControllerSetupFromFtp1(id: number, dataLoggerToken: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dataLoggerControllerSetupFromFtp1(id: number, dataLoggerToken: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataLoggerControllerSetupFromFtp1.');
        }
        if (dataLoggerToken === null || dataLoggerToken === undefined) {
            throw new Error('Required parameter dataLoggerToken was null or undefined when calling dataLoggerControllerSetupFromFtp1.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/data-logger/setup-ftp/step1/${encodeURIComponent(String(id))}/${encodeURIComponent(String(dataLoggerToken))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Step 3 of setup process of &lt;b&gt;DataLoggers&lt;/b&gt; sending data via FTP. It is used for initiating &lt;b&gt;DeviceTypes&lt;/b&gt;, &lt;b&gt;ChannelTypes&lt;/b&gt; and &lt;b&gt;Devices&lt;/b&gt;. Accepts as input the output of Step 2. Make sure that the &lt;i&gt;manufacturer&lt;/i&gt; property is added.&lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-devicecontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DeviceController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-devicetypecontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DeviceTypeController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-devicetypechanneltypecontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DeviceTypeChannelTypeController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-usercontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;UserController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param id 
     * @param responseDataLoggerSetupStep2 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerSetupFromFtp3(id: number, responseDataLoggerSetupStep2?: ResponseDataLoggerSetupStep2, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dataLoggerControllerSetupFromFtp3(id: number, responseDataLoggerSetupStep2?: ResponseDataLoggerSetupStep2, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dataLoggerControllerSetupFromFtp3(id: number, responseDataLoggerSetupStep2?: ResponseDataLoggerSetupStep2, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dataLoggerControllerSetupFromFtp3(id: number, responseDataLoggerSetupStep2?: ResponseDataLoggerSetupStep2, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataLoggerControllerSetupFromFtp3.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/data-logger/setup-ftp/step3/${encodeURIComponent(String(id))}`,
            responseDataLoggerSetupStep2,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Step 2 of setup process of &lt;b&gt;DataLoggers&lt;/b&gt; sending CSV data via FTP. It is used for mapping properties of the sent &lt;i&gt;file&lt;/i&gt; to properties of models in the system. &lt;b&gt;DataLogger&lt;/b&gt; to be setup, is identified by &lt;i&gt;id&lt;/i&gt; and &lt;b&gt;User&lt;/b&gt; performing operation is identified by &lt;i&gt;token&lt;/i&gt;. Endpoint response represents the results of the mapping (required in Step 3). If it is satisfactory, it is saved by setting the property &lt;i&gt;confirm&lt;/i&gt; to &lt;i&gt;true&lt;/i&gt;.&lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-usercontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;UserController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param id 
     * @param requestDataLoggerSetupCsv2ExcludingFileFormat 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerSetupFromFtpCsv2(id: number, requestDataLoggerSetupCsv2ExcludingFileFormat?: RequestDataLoggerSetupCsv2ExcludingFileFormat, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseDataLoggerSetupStep2>;
    public dataLoggerControllerSetupFromFtpCsv2(id: number, requestDataLoggerSetupCsv2ExcludingFileFormat?: RequestDataLoggerSetupCsv2ExcludingFileFormat, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseDataLoggerSetupStep2>>;
    public dataLoggerControllerSetupFromFtpCsv2(id: number, requestDataLoggerSetupCsv2ExcludingFileFormat?: RequestDataLoggerSetupCsv2ExcludingFileFormat, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseDataLoggerSetupStep2>>;
    public dataLoggerControllerSetupFromFtpCsv2(id: number, requestDataLoggerSetupCsv2ExcludingFileFormat?: RequestDataLoggerSetupCsv2ExcludingFileFormat, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataLoggerControllerSetupFromFtpCsv2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseDataLoggerSetupStep2>(`${this.configuration.basePath}/data-logger/setup-ftp/step2/csv/${encodeURIComponent(String(id))}`,
            requestDataLoggerSetupCsv2ExcludingFileFormat,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Step 2 of setup process of &lt;b&gt;DataLoggers&lt;/b&gt; sending XML data via FTP. It is used for mapping properties of the sent &lt;i&gt;file&lt;/i&gt; to properties of models in the system. &lt;b&gt;DataLogger&lt;/b&gt; to be setup, is identified by &lt;i&gt;id&lt;/i&gt; and &lt;b&gt;User&lt;/b&gt; performing operation is identified by &lt;i&gt;token&lt;/i&gt;. Endpoint response represents the results of the mapping (required in Step 3). If it is satisfactory, it is saved by setting the property &lt;i&gt;confirm&lt;/i&gt; to &lt;i&gt;true&lt;/i&gt;.&lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#atman-iot-usercontroller\&quot; target&#x3D;\&quot;_blank\&quot;&gt;UserController&lt;/a&gt;, &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param id 
     * @param requestDataLoggerSetupXml2ExcludingFileFormat 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerSetupFromFtpXml2(id: number, requestDataLoggerSetupXml2ExcludingFileFormat?: RequestDataLoggerSetupXml2ExcludingFileFormat, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ResponseDataLoggerSetupStep2>;
    public dataLoggerControllerSetupFromFtpXml2(id: number, requestDataLoggerSetupXml2ExcludingFileFormat?: RequestDataLoggerSetupXml2ExcludingFileFormat, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ResponseDataLoggerSetupStep2>>;
    public dataLoggerControllerSetupFromFtpXml2(id: number, requestDataLoggerSetupXml2ExcludingFileFormat?: RequestDataLoggerSetupXml2ExcludingFileFormat, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ResponseDataLoggerSetupStep2>>;
    public dataLoggerControllerSetupFromFtpXml2(id: number, requestDataLoggerSetupXml2ExcludingFileFormat?: RequestDataLoggerSetupXml2ExcludingFileFormat, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataLoggerControllerSetupFromFtpXml2.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (bearerAuth) required
        credential = this.configuration.lookupCredential('bearerAuth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResponseDataLoggerSetupStep2>(`${this.configuration.basePath}/data-logger/setup-ftp/step2/xml/${encodeURIComponent(String(id))}`,
            requestDataLoggerSetupXml2ExcludingFileFormat,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Used for receiving data, sent in CSV format by a &lt;b&gt;DataLogger&lt;/b&gt; identified by &lt;i&gt;id&lt;/i&gt; and &lt;i&gt;dataLoggerToken&lt;/i&gt;. If the &lt;b&gt;DataLogger&lt;/b&gt; is sending data via FTP, endpoint is called internally.&lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param id 
     * @param dataLoggerToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerUploadDataFromCsv(id: number, dataLoggerToken: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dataLoggerControllerUploadDataFromCsv(id: number, dataLoggerToken: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dataLoggerControllerUploadDataFromCsv(id: number, dataLoggerToken: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dataLoggerControllerUploadDataFromCsv(id: number, dataLoggerToken: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataLoggerControllerUploadDataFromCsv.');
        }
        if (dataLoggerToken === null || dataLoggerToken === undefined) {
            throw new Error('Required parameter dataLoggerToken was null or undefined when calling dataLoggerControllerUploadDataFromCsv.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/data-logger/data-from-csv/${encodeURIComponent(String(id))}/${encodeURIComponent(String(dataLoggerToken))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Used for receiving data, sent in XML format by a &lt;b&gt;DataLogger&lt;/b&gt; identified by &lt;i&gt;id&lt;/i&gt; and &lt;i&gt;dataLoggerToken&lt;/i&gt;. If the &lt;b&gt;DataLogger&lt;/b&gt; is sending data via FTP, endpoint is called internally.&lt;br&gt;&lt;br&gt;&lt;i&gt;&lt;b&gt;References:&lt;/b&gt; &lt;a href&#x3D;\&quot;/documentation.html#tocsdatalogger\&quot; target&#x3D;\&quot;_blank\&quot;&gt;DataLoggerModel&lt;/a&gt;&lt;/i&gt;
     * @param id 
     * @param dataLoggerToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dataLoggerControllerUploadDataFromXml(id: number, dataLoggerToken: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dataLoggerControllerUploadDataFromXml(id: number, dataLoggerToken: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dataLoggerControllerUploadDataFromXml(id: number, dataLoggerToken: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dataLoggerControllerUploadDataFromXml(id: number, dataLoggerToken: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling dataLoggerControllerUploadDataFromXml.');
        }
        if (dataLoggerToken === null || dataLoggerToken === undefined) {
            throw new Error('Required parameter dataLoggerToken was null or undefined when calling dataLoggerControllerUploadDataFromXml.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/data-logger/data-from-xml/${encodeURIComponent(String(id))}/${encodeURIComponent(String(dataLoggerToken))}`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
