import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetControllerService, Asset,
   AssetExcludingId, AssetGroupControllerService, AssetGroup, AssetGroupExcludingIdUserId } from '../rest';
import { AuthService } from '../auth/auth.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { EditPopUpComponent } from '../common/components/popups/edit-pop-up/edit-pop-up.component';
import { SavedElement } from '../common/components/buttons/add-new/add-new.component';
import { Subscription } from 'rxjs';
import { isAdminService } from 'src/app/auth/isAdmin.service';
import $ from 'jquery'
import angular from 'angular';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})
export class AssetsComponent implements OnInit {
  isAdmin = true;
  style ='{pointer-events: none}';
  subscription: Subscription;
  isUser = true;
  isDone = false;
  assets: Asset[] = [];
  serviceError = false;
  loading = false;
  newAsset = <AssetExcludingId>{};
  assetName: string;
  assetGroups: AssetGroup[] = [];
  assetDescription: string;
  selectedAssetGroupId: number = +this.route.snapshot.params['id'];
  selectedAssetGroup: AssetGroup;
  editMode = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private assetControllerService: AssetControllerService,
    private assetGroupControllerService: AssetGroupControllerService,
    private authService: AuthService,
    private isAdminService: isAdminService
  ) { }

  ngOnInit() {
    this.isAdmin = this.isAdminService.isAdmin();
    this.loading = true;
    this.findAssetGroups();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAssetGroupChange(assetGroup: AssetGroup) {
    if (this.selectedAssetGroupId !== assetGroup.id) {
      this.selectedAssetGroupId = assetGroup.id;
      this.router.navigate(['../' + this.selectedAssetGroupId], { relativeTo: this.route });
      this.findAssetGroups();
    }
  }

  findAssetGroups() {
    this.assetGroups = [];
    this.subscription = this.assetGroupControllerService.assetGroupControllerFindUserAssetGroups()
    .subscribe(
      value => {
        this.assetGroups.push(...value);
        for (var i=0; i < this.assetGroups.length; i++) {
          if (this.assetGroups[i].id === this.selectedAssetGroupId) {
            this.selectedAssetGroup = this.assetGroups[i];
            this.isDone = true;
            break;
          }
        }
        this.makeCallForAssets(this.selectedAssetGroupId);
        // this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  makeCallForAssets(id: number) {
    this.loading = true;
    this.assets = [];

    this.subscription = this.assetControllerService.assetControllerFind(id)
    .subscribe(
      value => {
        this.assets.push(...value);
        this.loading = false;
      },
      errorRes => {
        if(!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode)
        {
          this.serviceError = true;
          this.loading = false;
          return;
        }

        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.authService.logout();
            this.loading = false;
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  onAssetSelect(id: number) {
    this.router.navigate(['./asset/' + id + '/dashboard'], { relativeTo: this.route });
  }

  saveAsset(savedElement: SavedElement) {
    this.newAsset.description = savedElement.description;
    this.newAsset.name = savedElement.name;
    this.newAsset.assetGroupId = +this.route.snapshot.params['id'];
    this.subscription = this.assetControllerService.assetControllerCreate(this.newAsset)
    .subscribe(
      response => {
        this.makeCallForAssets(this.newAsset.assetGroupId);
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }

  delete(element) {
    this.assetControllerService.assetControllerDeleteById(element.id)
    .subscribe(
      response => {
        this.makeCallForAssets(this.route.snapshot.params['id']);
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }

  edit(editAsset: Asset) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'Asset', name: editAsset.name, description: editAsset.description};
    const dialogRef = this.dialog.open(EditPopUpComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        editAsset.description = result.description;
        editAsset.name = result.name;
        this.postChanges(editAsset);
      }
    });
    // editAsset.description = 'Renew Asset Group';
    // editAsset.name = 'The best Changer';
    // this.postChanges(editAsset);
  }

  postChanges(changedAsset: Asset) {
    const changedValues: AssetGroupExcludingIdUserId = {name: changedAsset.name, description: changedAsset.description}
    this.subscription = this.assetControllerService.assetControllerUpdateById(changedAsset.id, changedValues)
    .subscribe(
      response => {
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }
}
