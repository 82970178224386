import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { EditPopUpComponent } from '../common/components/popups/edit-pop-up/edit-pop-up.component';
import { DataLoggerControllerService, Device, DeviceControllerService, DeviceExcludingIdSerialNoDataLoggerIdDeviceTypeId } from '../rest';
import { AssetGroup } from '../rest/model/assetGroup';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {
  devices = [
    {id: 1, name: '1o', description: 'device'}
  ];
  loading = true;
  serviceError = false;
  onAssetGroupSelect: any;
  devicesFromDatalogger: Device[];
  assetGroup: AssetGroup;
  gotDevices: boolean = false;
  constructor(
    private dataLoggerControllerService: DataLoggerControllerService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private deviceControllerService: DeviceControllerService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const dataLoggerId = +this.route.snapshot.paramMap.get('id');
    this.showDevices(dataLoggerId);
  }

  showDevices(id: number) {
    this.devicesFromDatalogger = [];
    this.deviceControllerService.deviceControllerFind(id)
    .subscribe(
      response => {
        this.devicesFromDatalogger.push(...response);
        this.loading = false;
        this.gotDevices = true;
      },
      errorRes => {
        console.log(errorRes);
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  edit(editDevice: Device) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'Device', Device: editDevice};
    const dialogRef = this.dialog.open(EditPopUpComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        this.postChanges(result);
      }
    });
  }

  postChanges(editDeviceDescription) {
    const changedValue: DeviceExcludingIdSerialNoDataLoggerIdDeviceTypeId = {description: editDeviceDescription.description};
    this.deviceControllerService.deviceControllerUpdateById(editDeviceDescription.Device.id, changedValue)
    .subscribe(
      response => {
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }

  goToChannels(device) {
    this.router.navigate(['./device/' + device.id + '/channels'], {relativeTo: this.route});
  }
}
