import { Component, OnInit } from '@angular/core';
import { AssetControllerService, Asset, AssetGroup, VirtualAccessControllerService, RequestVirtualAccessRightExcludingAssetGroupId, VirtualAccess } from 'src/app/rest';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { SelectPopupComponent } from 'src/app/common/components/popups/select-popup/select-popup.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable, forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-user-asset',
  templateUrl: './user-asset.component.html',
  styleUrls: ['./user-asset.component.css']
})
export class UserAssetComponent implements OnInit {
  userId: number = +this.route.snapshot.params['id'];
  loading = true;
  gotAsset = false;
  subscription: Subscription;
  isAdmin = true;
  serviceError = false;
  userAssetRelations: VirtualAccess[];
  userAssets: Asset[];
  selectedAssetGroup: AssetGroup;
  assetsFromGroup: Asset[];
  unSelectedAssets: {selected: boolean, assetId: number, assetName: string}[];
  allRequests: Observable<VirtualAccess>[];
  addedAssetObject: RequestVirtualAccessRightExcludingAssetGroupId;

  constructor(
    private assetControllerService: AssetControllerService,
    private virtualAccessControllerService: VirtualAccessControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog
    ) { }

  ngOnInit() {
    this.getAssets();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getAssets() {
    this.userAssets = [];
    this.subscription = this.assetControllerService.assetControllerFindByUserId(this.userId)
    .subscribe(
      value => {
        this.userAssets.push(...value);
        this.getAssetRelations();
        this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  getAssetRelations() {
    this.userAssetRelations = [];
    this.subscription = this.virtualAccessControllerService.virtualAccessControllerFindByUserId(this.userId)
    .subscribe(
      value => {
        this.userAssetRelations.push(...value);
        this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {value: 'user-asset'};
    const dialogRef = this.dialog.open(SelectPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.selectedAssetGroup = result;
      this.findAssetsFromAssetGroup();
    });
   }

   findAssetsFromAssetGroup() {
     this.assetsFromGroup = [];
     this.subscription = this.assetControllerService.assetControllerFind(this.selectedAssetGroup.id)
     .subscribe(
      value => {
        this.assetsFromGroup.push(...value);
        this.gotAsset = true;
        this.findUnSelectedAssets();
        this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
   }

  findUnSelectedAssets() {
    this.unSelectedAssets = [];
    for (const asset of this.assetsFromGroup) {
      const val = this.userAssetRelations.findIndex(x => x.assetId === asset.id);
      if (val === -1) {
        this.unSelectedAssets.push({selected: false, assetId: asset.id, assetName: asset.name });
      }
    }
  }

   updateAssets() {
    this.allRequests = [];
    const addedAssets = this.unSelectedAssets.filter(x => x.selected === true);
    for (const asset of addedAssets) {
      this.addedAssetObject = {userId : this.userId, assetId: asset.assetId};
      this.allRequests.push(this.virtualAccessControllerService.virtualAccessControllerCreateAccessByRight(this.addedAssetObject));
    }
    forkJoin(this.allRequests)
    .subscribe(
      response => {
        this.getAssets();
        this.loading = false;
        this.gotAsset = false;
      },
      errorRes => {
        console.log(errorRes);
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
   }

   delete(element) {
    for (const userAssetRelation of this.userAssetRelations) {
      if (userAssetRelation.assetId === element.id) {
        this.subscription = this.virtualAccessControllerService.virtualAccessControllerDeleteById(userAssetRelation.id)
        .subscribe(
          response => {
            this.getAssets();
          },
          errorMessage => {
            console.log(errorMessage);
        });
        break;
      }
    }
  }
}
