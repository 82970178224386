import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Channel, ChannelType, DeviceControllerService } from 'src/app/rest';

export interface tempChannel {
  name: string;
  deviceTypeId: number;
  channelTypeId: number;
  id: number;
}

@Component({
  selector: 'app-device-channel',
  templateUrl: './device-channel.component.html',
  styleUrls: ['./device-channel.component.css']
})
export class DeviceChannelComponent implements OnInit {
  alldeviceChannelsTypes: ChannelType[];
  alldeviceChannels: Channel[];
  loading = true;
  serviceError = false;
  tempChannel: tempChannel[];
  deviceID: number;
  constructor(
    private deviceControllerService: DeviceControllerService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.deviceID = +this.route.snapshot.paramMap.get('id');
    this.getChannelTypes(this.deviceID);
  }

  getChannelTypes(id: number) {
    this.alldeviceChannelsTypes = [];
    this.deviceControllerService.deviceControllerFindChannelTypes(id)
    .subscribe(
      response => {
        this.alldeviceChannelsTypes.push(...response);
        this.getChannels(id);
      },
      errorRes => {
        console.log(errorRes);
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  getChannels(id) {
    this.alldeviceChannels = [];
    this.deviceControllerService.deviceControllerFindChannels(id)
    .subscribe(
      response => {
        this.alldeviceChannels.push(...response);
        console.log(response);
        this.loading = false;
      },
      errorRes => {
        console.log(errorRes);
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  slideUsed(channelOption) {
    console.log(channelOption);
    if (channelOption.sliced) {
      this.deviceControllerService.deviceControllerEnableChannelMonitoring
      (this.deviceID, channelOption.channelId, channelOption.activateAll)
      .subscribe(
        response => {
          this.loading = true;
          this.getChannelTypes(this.deviceID);
        },
        errorRes => {
          console.log(errorRes);
          if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
            this.serviceError = true;
            this.loading = false;
            return;
          }
          switch(parseInt(errorRes.error.error.statusCode)) {
            case 401:
              this.loading = false;
              // this.authService.logout();
            case 406:
              this.serviceError = true;
              this.loading = false;
          }
        }
      );
    } else if (!channelOption.sliced) {
      this.deviceControllerService.deviceControllerDisableChannelMonitoring
      (this.deviceID, channelOption.channelId, channelOption.activateAll)
      .subscribe(
        response => {
          this.loading = true;
          this.getChannelTypes(this.deviceID);
        },
        errorRes => {
          console.log(errorRes);
          if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
            this.serviceError = true;
            this.loading = false;
            return;
          }
          switch(parseInt(errorRes.error.error.statusCode)) {
            case 401:
              this.loading = false;
              // this.authService.logout();
            case 406:
              this.serviceError = true;
              this.loading = false;
          }
        }
      );
    }
  }
}
