import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardElementControllerService, DashboardElementWithScope } from 'src/app/rest';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  serviceError = false;
  @Input() dashboardElement: DashboardElementWithScope;
  @Input() fromComponent: string = 'nonDash';
  @Output() deletedDashboard = new EventEmitter<DashboardElementWithScope>();
  @Output() movedUpDashboard = new EventEmitter<DashboardElementWithScope>();
  @Output() movedDownDashboard = new EventEmitter<DashboardElementWithScope>();

  isAdmin = true;
  loading = true;
  constructor(
    private dashboardElementControllerService: DashboardElementControllerService
  ) { }

  ngOnInit() {
  }

  deleteDashboard(selectedDashboard: DashboardElementWithScope) {
    this.deletedDashboard.next(selectedDashboard);
  }

  moveUp(selectedDashboard: DashboardElementWithScope) {
    console.log(selectedDashboard);
    this.movedUpDashboard.next(selectedDashboard);
  }

  moveDown(selectedDashboard: DashboardElementWithScope) {
    this.movedDownDashboard.next(selectedDashboard);
  }
}
