import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DroneStatus } from '../models/droneStatus';
import { HangarStatus } from '../models/hangarStatus';
import { Mission } from '../models/mission';
import { MissionParam } from '../models/missionParam';

@Injectable({
  providedIn: 'root'
})
export class ThessalyAPIService {

  private basePath = 'http://10.96.12.115:2000/controller';
  private request = {};
  constructor(private http: HttpClient ) { }

  public controllerHangarGetState(): Observable<HangarStatus>;
  public controllerHangarGetState(): Observable<any> {
    return this.http.get(this.basePath + '/hangar');
  }

  public controllerDrone(): Observable<DroneStatus>;
  public controllerDrone():Observable<any> {
    return this.http.get(this.basePath + '/drone');
  }

  public controllerGetMission(queryParam: string): Observable<Mission>;
  public controllerGetMission(queryParam: string): Observable<any> {
    return this.http.get(this.basePath + '/missions/' + queryParam);
  }

  public controllerPostMission(queryParam: string, requestBody: MissionParam): Observable<Mission>;
  public controllerPostMission(queryParam: string, requestBody: MissionParam): Observable<any> {
    return this.http.post(this.basePath + '/missions/' + queryParam, requestBody);
  }

  public controllerGetImage(queryParam: string, requestBody: any): Observable<Mission>;
  public controllerGetImage(queryParam: string, requestBody: any): Observable<any> {
    return this.http.get(this.basePath + '/images/' + queryParam,
    {
      headers : new HttpHeaders({"Content-Type": "application/json"}),
      params: requestBody,
      observe: 'body'
    });
  }
}
