import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {default as _rollupMoment, Moment} from 'moment';
import * as _moment from 'moment';
import { DataService } from 'src/app/services/data-service';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-year-component',
  templateUrl: './year-component.component.html',
  styleUrls: ['./year-component.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class YearComponentComponent implements OnInit {

  date = new FormControl(moment());
  minDate: Date;
  maxDate: Date;
  minYear: number;
  maxYear: number;
  constructor(private service: DataService) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    this.minDate = new Date(currentYear - 20, 0, 0);
    this.maxDate = new Date(currentYear, currentMonth, currentDay);
    this.maxYear = currentYear;
    this.minYear = this.minDate.getFullYear();
   }

  ngOnInit() {
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  previousDate() {
    const ctrlValue = this.date.value;
    if (this.minYear === ctrlValue.year()) {
    } else {
      ctrlValue.year(ctrlValue.year() - 1);
      this.date.setValue(ctrlValue);
      const appEndDate = new Date(this.date.value.year(), 0, 1);
      this.service.changeDate(appEndDate);
    }
  }

  nextDate() {
    const ctrlValue = this.date.value;
    if (this.maxYear === ctrlValue.year()) {
    } else {
      ctrlValue.year(ctrlValue.year() + 1);
      this.date.setValue(ctrlValue);
      const appEndDate = new Date(this.date.value.year(), 0, 1);
      this.service.changeDate(appEndDate);
    }
  }
}
