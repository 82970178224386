import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css']
})
export class MonitoringComponent implements OnInit {
  monitoringList = [
    {id: 1, name: 'Dashboard', description: 'Manage Dashboards'},
  ];
  selectedItem = 'default';
  constructor() { }

  ngOnInit() {
  }

  moveTodashboard() {
    this.selectedItem = 'dashboard';
  }

}
