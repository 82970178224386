import { Component, OnInit } from '@angular/core';
import {
  UserControllerService,
  User,
  UserExcludingPasswdEmailRolesIsVirtualSubscriptionReferenceCode,
  UserExcludingIdUsernameIsVirtualRolesSubscriptionReferenceCode
} from '../rest';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  userRegister = <UserExcludingIdUsernameIsVirtualRolesSubscriptionReferenceCode>{};
  users: UserExcludingPasswdEmailRolesIsVirtualSubscriptionReferenceCode[];
  loading = true;
  serviceError = false;
  repeatPassword = '';
  isAdmin = true;

  constructor(
    private userControllerService: UserControllerService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.users = [];
    this.userControllerService.userControllerGetVirtualUsers()
      .subscribe(
        value => {
          this.users.push(...value);
          this.loading = false;
        },
        errorRes => {
          if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
            this.serviceError = true;
            this.loading = false;
            return;
          }
          switch (parseInt(errorRes.error.error.statusCode)) {
            case 401:
              this.authService.logout();
              this.loading = false;
            case 406:
              this.serviceError = true;
              this.loading = false;
          }
        }
      );
  }

  registerVirtualUser() {
    this.userControllerService.userControllerCreateVirtual(this.userRegister)
      .subscribe(
        value => {
          this.getUsers();
          this.loading = false;
        },
        errorRes => {
          if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
            this.serviceError = true;
            this.loading = false;
            return;
          }
          switch (parseInt(errorRes.error.error.statusCode)) {
            case 401:
              this.authService.logout();
              this.loading = false;
            case 406:
              this.serviceError = true;
              this.loading = false;
          }
        }
      );
  }

  delete(element) {
    this.userControllerService.userControllerDeleteVirtualUserById(element.id)
      .subscribe(
        value => {
          this.getUsers();
          this.loading = false;
        },
        errorRes => {
          if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
            this.serviceError = true;
            this.loading = false;
            return;
          }
          switch (parseInt(errorRes.error.error.statusCode)) {
            case 401:
              this.authService.logout();
              this.loading = false;
            case 406:
              this.serviceError = true;
              this.loading = false;
          }
        }
      );
  }

  permitions(element) {
    this.router.navigate([element.id + '/assets'], { relativeTo: this.route });
  }
}
