import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectPopupComponent } from '../select-popup/select-popup.component';

@Component({
  selector: 'app-edit-pop-up',
  templateUrl: './edit-pop-up.component.html',
  styleUrls: ['./edit-pop-up.component.css']
})
export class EditPopUpComponent implements OnInit {
  editedValueDescription: string;
  editedValueUnit: string;
  editedValueName: string;
  editedValue: string;
  editParam1: string = 'Name';
  editParam2: string = 'Description';
  editParam3: string = 'Unit';
  isChannelType: boolean = false;
  isDeviceType: boolean = false;
  nonEditParam1: string;
  nonEditParam2: string;
  types = ['SPOT_VALUE', 'COUNTER', 'TEXT'];
  editType: string;

  constructor(
    public dialogRef: MatDialogRef<SelectPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      if (('DeviceType' in data)) {
        this.isDeviceType = true;
        this.editParam1 = 'Category';
        this.editParam2 = 'Description';
        this.editedValue = data.value;
        this.nonEditParam1 = data.DeviceType.manufacturer;
        this.nonEditParam2 = data.DeviceType.model;
        this.editedValueName = data.DeviceType.category;
        this.editedValueDescription = data.DeviceType.description;
      } else if (('ChannelType' in data)) {
        this.isChannelType = true;
        this.editParam1 = 'Category';
        this.editParam2 = 'Description';
        this.editType = data.ChannelType.channelType;
        this.editedValue = data.value;
        this.editedValueName = data.ChannelType.name;
        this.editedValueDescription = data.ChannelType.description;
        this.editedValueUnit = data.ChannelType.unit;
      } else if (('Device' in data)) {
        this.editedValue = data.value;
        this.editedValueName = data.Device.name;
        this.editedValueDescription = data.Device.description;
      } else {
        this.editedValue = data.value;
        this.editedValueName = data.name;
        this.editedValueDescription = data.description;
      }
    }

  ngOnInit() {
  }

  save() {
    this.data.name = this.editedValueName;
    this.data.description = this.editedValueDescription;
    if (this.isChannelType) {
      this.data.unit = this.editedValueUnit;
    }
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }
}
