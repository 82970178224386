import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserExcludingIdUsernameIsVirtualRolesSubscriptionReferenceCode, UserExcludingIdEmailIsVirtualRolesSubscriptionReferenceCode } from 'src/app/rest';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  error: string = null;
  isLoading = false;
  loginMode = true;
  refUrl: string;
  user = <UserExcludingIdUsernameIsVirtualRolesSubscriptionReferenceCode>{};
  credentials = <UserExcludingIdEmailIsVirtualRolesSubscriptionReferenceCode>{};

  repeatPasswd: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  onSubmit(form: NgForm) {
      this.user.email = this.user.email;
      this.refUrl = this.route.snapshot.queryParamMap.get('referenceCode');
      this.isLoading = true;
      this.authService.register(this.refUrl, this.user).subscribe(
        response => {
          this.router.navigate(['/auth']);
          this.isLoading = false;
        },
        errorMessage => {
          console.log(errorMessage);
          this.error = errorMessage;
          alert('Error On registration');
          this.isLoading = false;
        }
      );
  }
}
