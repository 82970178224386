import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { EditPopUpComponent } from 'src/app/common/components/popups/edit-pop-up/edit-pop-up.component';
import { ChannelType, ChannelTypeExcludingIdNameDeviceTypeId, DeviceTypeChannelTypeControllerService } from 'src/app/rest';

@Component({
  selector: 'app-channel-type',
  templateUrl: './channel-type.component.html',
  styleUrls: ['./channel-type.component.css']
})
export class ChannelTypeComponent implements OnInit {
  allChannelTypes: ChannelType[] = [];
  page = 0;
  count = 10;
  loading = true;
  serviceError = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private deviceTypeChannelTypeControllerService: DeviceTypeChannelTypeControllerService) { }

  ngOnInit() {
    const deviceTypeId = this.route.snapshot.paramMap.get('id');
    this.getChannelTypes(+deviceTypeId);
  }

  getChannelTypes(id: number) {
    this.deviceTypeChannelTypeControllerService.deviceTypeChannelTypeControllerFind(id, this.page, this.count)
    .subscribe(
      response => {
        this.allChannelTypes = response;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      });
  }

  edit(editChannelType: ChannelType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'Channel Type', ChannelType: editChannelType};
    const dialogRef = this.dialog.open(EditPopUpComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        this.postChanges(result);
      }
    });
  }

  postChanges(editChannelType) {
    const changedValues: ChannelTypeExcludingIdNameDeviceTypeId = {channelType: editChannelType.channelType,
      description: editChannelType.description, category: editChannelType.category, unit: editChannelType.unit};
    this.deviceTypeChannelTypeControllerService.deviceTypeChannelTypeControllerUpdateById(editChannelType.id, changedValues)
    .subscribe(
      response => {
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }
}
