import { Component, Input, OnInit } from '@angular/core';
import {rotate270} from '2d-array-rotation';
import * as moment from 'moment';
import { ResizedEvent } from 'angular-resize-event';
import { Observable, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { DashboardElementWithScope, DeviceChannelControllerService, ResponseDeviceChannelData } from 'src/app/rest';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit {
  @Input() dashboard: DashboardElementWithScope;
  chartTitle:  string;
  chartId: string;
  deviceArray = [];
  isLoading = false;
  clicked = false;
  randomColors: string[] = [];
  posts: any;
  period: number;
  page = 0;
  allChannels = [];
  allRows = [];
  statsCount = 200;

  constructor( 
    private deviceChannelControllerService: DeviceChannelControllerService,
    public authService: AuthService) { }

  ngOnInit() {
    this.chartTitle = this.dashboard.dashboardElement.title;
    this.deviceArray = (this.dashboard.scope);
    this.onRequest();
  }

  onRequest() {
    const allRequests: Observable<ResponseDeviceChannelData>[] = [];
    const allResponses: ResponseDeviceChannelData[] = [];
    for (const i in this.deviceArray) {
      allRequests
      .push(this.deviceChannelControllerService.deviceChannelControllerFindByPeriod(this.deviceArray[i].deviceId,
        + this.deviceArray[i].channelId, 'Tue Dec 08 2020 00:00:00 GMT+0200 (Eastern European Standard Time)',
        'Tue Dec 08 2020 23:59:59 GMT+0200 (Eastern European Standard Time)', this.page, this.statsCount, 'none', 'none'));
    }

    forkJoin(allRequests)
    .subscribe
    (responses => {
      allResponses.push(...responses);
      for (let countResponse in responses) {
        const color = (this.getRandomColor());
        this.randomColors.push(color);
      }
      // this.posts = allResponses;
      this.addValue(allResponses);
    },
    errorMessage => {
      // this.authService.logout();
      console.log(errorMessage);
    });
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  findMinMaxDate(data: ResponseDeviceChannelData[]) {
    let minDate: moment.Moment  = moment(data[0].dataFloat[data[0].dataFloat.length - 1].timestamp);
    let minDateFormat: string = minDate.format('YYYY-MMM-DD HH:mm');
    let maxDate: moment.Moment = moment(data[0].dataFloat[0].timestamp);
    let maxDateFormat: string = maxDate.format('YYYY-MMM-DD HH:mm');
    for (const channel of data) {
      if (maxDate.isBefore(channel.dataFloat[0].timestamp)) {
        maxDate = moment(channel.dataFloat[0].timestamp);
        maxDateFormat = maxDate.format('YYYY-MMM-DD HH:mm');
      }
      if (minDate.isAfter(channel.dataFloat[channel.dataFloat.length - 1].timestamp)) {
        minDate = moment(channel.dataFloat[channel.dataFloat.length - 1].timestamp);
        minDateFormat = minDate.format('YYYY-MMM-DD HH:mm');
      }
    }
    return {maxDate, minDate, maxDateFormat, minDateFormat};
  }

  addValue(data: ResponseDeviceChannelData[]) {
    let counter = 0;
    const endarray = [];
    const arrayDatasets = [];
    this.allChannels = [];
    const arrayLabel = [];
    const {maxDate, minDate, maxDateFormat, minDateFormat} = this.findMinMaxDate(data);
    const allDates = [];

    for (const channel of data) {
      this.allChannels.push(channel.channelType.name);
      let tempMinDate = moment(channel.dataFloat[channel.dataFloat.length - 1].timestamp);
      const dataXY = [];
      let flagDate = maxDate;
      let xy = {x: null, y: null};
      channel.dataFloat.forEach(element => {
        const {period, measurement, timestamp} = element;
        this.period = period;
        const dateString = moment(timestamp).format('YYYY-MMM-DD HH:mm');

        while (moment(timestamp).isBefore(flagDate)) {
          xy = {
            x: flagDate.format('YYYY-MMM-DD HH:mm'),
            y: null
          };
          flagDate = moment(flagDate).subtract(this.period, 'seconds');
          dataXY.push(xy);
        }

        if (moment(timestamp).isSame(flagDate)) {
          xy = {
            x: dateString,
            y: measurement
          };
          flagDate = moment(flagDate).subtract(this.period, 'seconds');
          dataXY.push(xy);
        }
        arrayLabel.push(dateString);
      });

      counter += 1;
      while (minDate.isBefore(tempMinDate)) {
      tempMinDate = moment(tempMinDate).subtract(this.period, 'seconds');
      xy = {
        x: tempMinDate.format('YYYY-MMM-DD HH:mm'),
        y: null
      };
      dataXY.push(xy);
      }
      allDates.push(dataXY);
    }

    this.allRows = rotate270(allDates);
    this.createInverter();
  }

  createInverter() {
    google.charts.load('current', { packages: ['corechart'] });
    const testparam = this.allRows;
    const channels = this.allChannels;
    const randomColor = this.randomColors;
    const chartId = this.chartId;

    // const options = this.options;
    google.charts.setOnLoadCallback(drawThisChart);
    function drawThisChart() {
      const dataRow = [];
      testparam.forEach(element => {
        const xValues = [];
        element.forEach(x => {
          xValues.push(x.y);
        });
        dataRow.push([element[0].x, ...xValues]);
      });
      const firstRow = ['timestamp'];
      channels.forEach(channel => {
        firstRow.push(channel);
      });
      dataRow.splice(0, 0, firstRow);
      const newData = google.visualization.arrayToDataTable(dataRow);
      const chart = new google.visualization.Timeline(document.getElementById(chartId));
      chart.draw(newData,  {
        // interpolateNulls: true,
        colors: randomColor
    });
    }
  }

  onResized(event: ResizedEvent) {
    // this.createInverter();
  }
}