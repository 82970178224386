import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location-service';

@Component({
  selector: 'app-drone-map',
  templateUrl: './drone-map.component.html',
  styleUrls: ['./drone-map.component.css']
})
export class DroneMapComponent implements OnInit {
  title = 'The Drone Location';
  homeLongitude: number = 10;
  homeLatitude: number = 10;
  currentLongtitude: number = 100;
  currentLatitude: number = 100;

  constructor(private locationService: LocationService) { }

  ngOnInit() {
    this.locationService.$homeLatitude
    .subscribe( response => {
      this.homeLongitude = response;
    });
    this.locationService.$homeLatitude
    .subscribe( response => {
      this.homeLatitude = response;
    });
    this.locationService.$currentLongtitude
    .subscribe( response => {
      this.currentLongtitude = response;
    });
    this.locationService.$currentLatitude
    .subscribe( response => {
      this.currentLatitude = response;
    });
  }

}
