import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { AssetGroupControllerService, 
  DeviceGroupMembershipControllerService, 
  DeviceGroupMembership, AssetGroup, 
  DataLogger, DeviceControllerService, 
  Device, 
  DeviceExcludingDescriptionSerialNoDataLoggerIdDeviceTypeId, 
  DeviceWithType, 
  DeviceGroupMembershipExcludingId } from 'src/app/rest';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { SelectPopupComponent } from 'src/app/common/components/popups/select-popup/select-popup.component';
import { Observable, forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-asset-devices',
  templateUrl: './asset-devices.component.html',
  styleUrls: ['./asset-devices.component.css']
})
export class AssetDevicesComponent implements OnInit {
  addedDeviceAssetRelation: DeviceGroupMembershipExcludingId;
  subscription: Subscription;
  selectedAssetId: number = +this.route.snapshot.params['id'];
  devices: DeviceGroupMembership[];
  loading: boolean = true;
  serviceError: boolean = false;
  isAdmin: boolean = true;
  devicesFromDatalogger: Device[];
  selectedDatalogger= <DataLogger>{};
  gotDevices: boolean = false;
  checked = false;
  labelPosition: 'after';
  devicesIds: DeviceExcludingDescriptionSerialNoDataLoggerIdDeviceTypeId[];
  unSelectedDevices: {selected: boolean, serialNo: string,  deviceId: number}[];
  newDevices: {selected: boolean, serialNo: string,  deviceId: number}[];
  devicesInfo: DeviceWithType[];
  onDeviceSelect: any;
  device: any;

  constructor(
    private deviceGroupMembershipControllerService: DeviceGroupMembershipControllerService,
    private route: ActivatedRoute,
    private deviceControllerService: DeviceControllerService,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getDevices();
  }

  getDevices()  {
    this.devices = [];
    this.devicesIds = [];
    this.subscription = this.deviceGroupMembershipControllerService.deviceGroupMembershipControllerFindByAssetId(this.selectedAssetId)
    .subscribe(
      value => {
        this.devices.push(...value);
        this.loading = false;
        for (const deviceTemp of this.devices) {
          this.devicesIds.push({id: deviceTemp.deviceId});
        }
        this.getDeviceInfo();
      },
      errorRes => {
        console.log(errorRes);
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch (parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  getDeviceInfo() {
    this.devicesInfo = [];
    this.subscription = this.deviceControllerService.deviceControllerGetDevicesWithType(this.devicesIds)
    .subscribe(
      value => {
        this.devicesInfo.push(...value);
        this.loading = false;
      },
      errorRes => {
        console.log(errorRes);
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'asset-device'};
    const dialogRef = this.dialog.open(SelectPopupComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.selectedDatalogger = result;
      this.showDevices(this.selectedDatalogger.id);
    });
   }

   showDevices(id: number) {
    this.devicesFromDatalogger = [];
    this.subscription = this.deviceControllerService.deviceControllerFind(id)
    .subscribe(
      value => {
        this.devicesFromDatalogger.push(...value);
        this.setDevices();
        this.loading = false;
        this.gotDevices = true;
      },
      errorRes => {
        console.log(errorRes);
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  setDevices() {
    this.unSelectedDevices = [];
    for (let device of this.devicesFromDatalogger) {
      var val = this.devices.findIndex(x => x.deviceId === device.id);
      if (val === -1) {
        this.unSelectedDevices.push({selected: false, serialNo: device.serialNo, deviceId: device.id});
      }
    }
  }

  updateSelectedDevices() {
    let all_requests: Observable<DeviceGroupMembership>[] = [];
    this.newDevices = this.unSelectedDevices.filter(x => x.selected ===true);
    for (let device of this.newDevices) {
      this.addedDeviceAssetRelation = {assetId: this.selectedAssetId, deviceId: device.deviceId};
      all_requests.push(this.deviceGroupMembershipControllerService.deviceGroupMembershipControllerCreate(this.addedDeviceAssetRelation));
    }
    forkJoin(all_requests)
    .subscribe(
      response => {
        this.getDevices();
        this.loading = false;
        this.gotDevices = false;
      },
      errorRes => {
        console.log(errorRes);
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
  }

  delete(element) {
    var deletedDeviceRelation: DeviceGroupMembership = this.devices.find(x => x.deviceId === element.device.id);
    this.subscription = this.deviceGroupMembershipControllerService.deviceGroupMembershipControllerDeleteById(deletedDeviceRelation.id)
    .subscribe(
      response => {
        this.getDevices();
      },
      errorMessage => {
        console.log(errorMessage);
      });
  }
}
