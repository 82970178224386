import { Component, OnInit } from '@angular/core';
import { Token } from '../../rest/model/token';
import { DeviceEventControllerService, DeviceEventWithRelations } from 'src/app/rest';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

@Component({
  selector: 'app-telemetry-event',
  templateUrl: './telemetry-event.component.html',
  styleUrls: ['./telemetry-event.component.css']
})
export class TelemetryEventComponent implements OnInit {
  serviceError = false;
  loading = false;
  telemetryEventArray: DeviceEventWithRelations[] = [];
  events: any;
  userID: number;

  constructor(private deviceEventController: DeviceEventControllerService) { }

  ngOnInit() {
    this.getIdFromToken();
    this.getEvents();
  }
  
  getEvents() {
    this.loading = true;
    this.deviceEventController.deviceEventControllerFind(7, 0, 10)
    .subscribe(response => {
      this.telemetryEventArray = response;
      this.loading = false;
    }, errorRes => {
      if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
        this.serviceError = true;
        this.loading = false;
        return;
      }
      switch (parseInt(errorRes.error.error.statusCode)) {
        case 401:
          this.loading = false;
        case 406:
          this.serviceError = true;
          this.loading = false;
      }
    });
  }

  getIdFromToken() {
    const token: Token = JSON.parse(localStorage.getItem('token')).token;
    const decoded = jwt_decode(token);
    this.userID = decoded.id;
  }

  checkSeen(id: number, counter: number) {
    const view = JSON.parse(this.telemetryEventArray[counter].viewedBy);
    view.map(v => parseInt(v, 10));
    if (view.includes(this.userID)) {
      return true;
    } else {
      return false;
    }
  }

  seeEvent(eventID: number) {
    this.deviceEventController.deviceEventControllerUpdateViewedBy(eventID)
    .subscribe(response => {
      this.getEvents();
    }, errorRes => {
      if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
        this.serviceError = true;
        this.loading = false;
        return;
      }
      switch (parseInt(errorRes.error.error.statusCode)) {
        case 401:
          this.loading = false;
        case 406:
          this.serviceError = true;
          this.loading = false;
      }
    });
  }

  timeEvent(time: string) {
    return moment(time).format('YYYY-MMM-DD HH:mm');
  }
}
