import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Mission } from 'src/app/models/mission';
import { ThessalyAPIService } from 'src/app/services/thessaly-api.service';

@Component({
  selector: 'app-thermography',
  templateUrl: './thermography.component.html',
  styleUrls: ['./thermography.component.css']
})
export class ThermographyComponent implements OnInit {
  errorMessage: string;
  imageIds: Mission;

  constructor(
    private thessalyApiService: ThessalyAPIService,
    private route: ActivatedRoute) { }

  ngOnInit() {
   // this.getImages();
  }

  getImages(missionName: string) {
    this.thessalyApiService.controllerGetImage('GetImageIDs', {MissionName: missionName})
    .subscribe(
      response => {
        this.imageIds = response;
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  downloadImage(missionName: string, imageId: Mission[], imageRes: string) {
    this.thessalyApiService.controllerGetImage('DownloadImage', {MissionName: missionName, ImageID: imageId, ImageRes: imageRes})
    .subscribe(
      response => {
        console.log(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }
}
