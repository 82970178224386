import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thermography-event',
  templateUrl: './thermography-event.component.html',
  styleUrls: ['./thermography-event.component.css']
})
export class ThermographyEventComponent implements OnInit {
  thermographyEventArray = [];
  constructor() { }

  ngOnInit() {
  }

}
