import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AssetGroupControllerService, AssetGroup } from 'src/app/rest';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-management-popup',
  templateUrl: './management-popup.component.html',
  styleUrls: ['./management-popup.component.css']
})
export class ManagementPopupComponent implements OnInit {
  form: FormGroup;
  assetSelect: AssetGroup;
  loading: boolean = false;
  serviceError: boolean = false;
  description: string;
  allAssetGroups: AssetGroup[] = [];

  constructor(
    private router: Router,
    private assetGroupControllerService: AssetGroupControllerService,
    private fb: FormBuilder,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ManagementPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.description = data.assetSelect;
    }

  ngOnInit() {
    this.assetGroupControllerService.assetGroupControllerFindUserAssetGroups()
    .subscribe(
      value => {
        this.allAssetGroups.push(...value);
        this.loading = false;
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      }
    );
    
    this.form = this.fb.group({
      description: [this.description, []]});
  }
  save() {
    console.log(this.assetSelect.id);
    this.dialogRef.close(this.form.value);
    this.router.navigate(['assetGroup/' + this.assetSelect.id]);
  }
  close() {
      this.dialogRef.close();
    }
}
