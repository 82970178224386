import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
import { AssetGroupsComponent } from './asset-groups/asset-groups.component';
import { AssetsComponent } from './assets/assets.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EventsComponent } from './events/events.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { MissionControllerComponent } from './mission-controller/mission-controller.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { UsersComponent } from './users/users.component';
import { DataLoggersComponent } from './data-loggers/data-loggers.component';
import { DeviceComponent } from './device/device.component';
import { AssetDevicesComponent } from './assets/asset-devices/asset-devices.component';
import { UserAssetComponent } from './assets/user-asset/user-asset.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { DashboardListComponent } from './dashboard/dashboard-list/dashboard-list.component';
import { DeviceManagementComponent } from './device/device-management/device-management.component';
import { DeviceTypeComponent } from './device/device-type/device-type.component';
import { ChannelTypeComponent } from './device/device-type/channel-type/channel-type.component';
import { DeviceChannelComponent } from './device/device-channel/device-channel.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';
import { isAdminService } from './auth/isAdmin.service';

const routes: Routes = [
  {path: 'assetGroup/:id/asset/:id/dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
  // {path: 'assetGroup/:id/asset/:id/events', component: EventsComponent, canActivate: [AuthGuardService]},
  // {path: 'assetGroup/:id/asset/:id/missionController', component:  MissionControllerComponent, canActivate: [AuthGuardService]},
  {path: 'assetGroup/:id/asset/:id/devices', component: AssetDevicesComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'assetGroup/:id', component: AssetsComponent, canActivate: [AuthGuardService]},
  {path: 'users/:id/assets', component: UserAssetComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'monitoring/dashboard', component: DashboardListComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'monitoring', component: MonitoringComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'deviceManagement/datalogger/:id/device/:id/channels', component: DeviceChannelComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'deviceManagement/deviceType/:id', component: ChannelTypeComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'deviceManagement/deviceType', component: DeviceTypeComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'deviceManagement/datalogger/:id', component: DeviceComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'deviceManagement', component: DeviceManagementComponent, canActivate: [AuthGuardService,isAdminService]},
  //{path: 'assetGroups', component: AssetGroupsComponent, canActivate: [AuthGuardService]},
  {path: 'home', component: AssetGroupsComponent, canActivate: [AuthGuardService]},
  {path: 'adminDashboard', component: AdminDashboardComponent, canActivate: [isAdminService,AuthGuardService]},
  {path: 'dataLoggers', component: DataLoggersComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: 'users', component: UsersComponent, canActivate: [AuthGuardService,isAdminService]},
  {path: '404', component: ErrorPageComponent},
  {path: 'login', component: AuthComponent},
  {path: 'signup', component: SignupComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '**', component: ErrorPageComponent, pathMatch: 'full'}
  // {path: '**', redirectTo: '404', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
