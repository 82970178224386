import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import jwt_decode from 'jwt-decode';
import {
  Asset, AssetControllerService, Token,
  UserControllerService, UserExcludingPasswdEmailRolesIsVirtualSubscriptionReferenceCode
} from 'src/app/rest';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-management',
  templateUrl: './dashboard-management.component.html',
  styleUrls: ['./dashboard-management.component.css']
})
export class DashboardManagementComponent implements OnInit {
  loading = true;
  subscription: Subscription;
  dashboardAssetsArray: Asset[];
  users: UserExcludingPasswdEmailRolesIsVirtualSubscriptionReferenceCode[];
  serviceError = false;
  showAssetDashboard = false;
  showHomeDashboard = false;
  currentUserId: number;
  userId: number;
  currentUserName: string;
  currentUserObject: UserExcludingPasswdEmailRolesIsVirtualSubscriptionReferenceCode;

  constructor(
    private userControllerService: UserControllerService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private assetControllerService: AssetControllerService) { }

  ngOnInit() {
    this.getIdFromToken();
    this.getUsers();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getIdFromToken() {
    const token: Token = JSON.parse(localStorage.getItem('token')).token;
    const decoded = jwt_decode(token);
    this.currentUserId = decoded.id;
    this.currentUserName = decoded.name;
  }

  getUsers() {
    this.users = [];
    this.currentUserObject = { id: this.currentUserId, username: this.currentUserName };
    this.users.push(this.currentUserObject);
    this.subscription = this.userControllerService.userControllerGetVirtualUsers()
      .subscribe(
        value => {
          this.users.push(...value);
          this.loading = false;
        },
        errorRes => {
          if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
            this.serviceError = true;
            this.loading = false;
            return;
          }
          switch (parseInt(errorRes.error.error.statusCode)) {
            case 401:
              this.authService.logout();
              this.loading = false;
            case 406:
              this.serviceError = true;
              this.loading = false;
          }
        }
      );
  }

  getAssets(userId: number) {
    this.dashboardAssetsArray = [];
    this.subscription = this.assetControllerService.assetControllerFindByUserId(userId)
      .subscribe(
        value => {
          this.dashboardAssetsArray.push(...value);
          this.loading = false;
          this.showAssetDashboard = true;
        },
        errorRes => {
          if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
            this.serviceError = true;
            this.loading = false;
            return;
          }
          switch (parseInt(errorRes.error.error.statusCode)) {
            case 401:
              this.loading = false;
              this.authService.logout();
            case 406:
              this.serviceError = true;
              this.loading = false;
          }
        });
  }

  setUserId(id: number) {
    this.userId = id;
  }

  openDashboard(whereToGo, assetId) {
    this.router.navigate(['./dashboard', { type: whereToGo, userId: this.userId, assetId: assetId }], { relativeTo: this.route });
  }
}
