import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { DropdownDirective } from './common/directives/dropdown.directive';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AssetGroupsComponent } from './asset-groups/asset-groups.component';
import { AssetsComponent } from './assets/assets.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MediaObjectRoundPillComponent } from './common/components/media-object-round-pill/media-object-round-pill.component';
import { MediaObjectRoundComponent } from './common/components/media-object-round/media-object-round.component';
import { EventsComponent } from './events/events.component';
import { AuthComponent } from './auth/auth.component';
import { LoadingSpinnerComponent } from './common/components/loading-spinner/loading-spinner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule, MatInputModule, MatTabsModule, MatDatepickerModule, MatNativeDateModule,
  MatFormFieldModule, MatButtonModule, MatSidenavModule, MatCardModule, MatExpansionModule, MatDialogModule,
  MatCheckboxModule, MatSlideToggleModule } from '@angular/material';
import { NotificationComponent } from './header/notification/notification.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { RangeFooterComponent } from './common/components/range-footer/range-footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { YearComponentComponent } from './common/components/range-footer/year-component/year-component.component';
import { MonthComponentComponent } from './common/components/range-footer/month-component/month-component.component';
import { FulldateComponentComponent } from './common/components/range-footer/fulldate-component/fulldate-component.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MissionControllerComponent } from './mission-controller/mission-controller.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { StatusComponent } from './mission-controller/status/status.component';
import { ControlsComponent } from './mission-controller/controls/controls.component';
import { ConfigComponent } from './mission-controller/config/config.component';
import { MatSelectModule } from '@angular/material/select';
import { DialogPopupComponent } from './mission-controller/mission/dialog-popup/dialog-popup.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ManagementComponent } from './admin-dashboard/management/management.component';
import { DataLoggersComponent } from './data-loggers/data-loggers.component';
import { UsersComponent } from './users/users.component';
import { AlertPopupComponent } from './common/components/popups/alert-popup/alert-popup.component';
import { ManagementPopupComponent } from './common/components/popups/management-popup/management-popup.component';
import { DeviceComponent } from './device/device.component';
import { AssetDevicesComponent } from './assets/asset-devices/asset-devices.component';
import { SelectPopupComponent } from './common/components/popups/select-popup/select-popup.component';
import { UserAssetComponent } from './assets/user-asset/user-asset.component';
import { EditPopUpComponent } from './common/components/popups/edit-pop-up/edit-pop-up.component';
import { AddNewComponent } from './common/components/buttons/add-new/add-new.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { ThermographyComponent } from './common/components/thermography/thermography.component';
import { DroneMapComponent } from './common/components/drone-map/drone-map.component';
import { ThermographyEventComponent } from './events/thermography-event/thermography-event.component';
import { ImageViewComponent } from './common/components/image-view/image-view.component';
import { TelemetryEventComponent } from './events/telemetry-event/telemetry-event.component';
import { AgmCoreModule } from '@agm/core';
import { HangarControllerComponent } from './mission-controller/hangar-controller/hangar-controller.component';
import { DroneControllerComponent } from './mission-controller/drone-controller/drone-controller.component';
import { MissionComponent } from './mission-controller/mission/mission.component';
import { HistoryComponent } from './mission-controller/history/history.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { LineChartComponent } from './common/components/charts/line-chart/line-chart.component';
import { PieChartComponent } from './common/components/charts/pie-chart/pie-chart.component';
import { GeoChartComponent } from './common/components/charts/geo-chart/geo-chart.component';
import { ScatterChartComponent } from './common/components/charts/scatter-chart/scatter-chart.component';
import { HistogramComponent } from './common/components/charts/histogram/histogram.component';
import { AreaChartComponent } from './common/components/charts/area-chart/area-chart.component';
import { ComboChartComponent } from './common/components/charts/combo-chart/combo-chart.component';
import { BubbleChartComponent } from './common/components/charts/bubble-chart/bubble-chart.component';
import { TimelineComponent } from './common/components/charts/timeline/timeline.component';
import { CandlestickChartComponent } from './common/components/charts/candlestick-chart/candlestick-chart.component';
import { TableComponent } from './common/components/charts/table/table.component';
import { HeatMapsComponent } from './common/components/charts/heat-maps/heat-maps.component';
import { SankeyDiagramComponent } from './common/components/charts/sankey-diagram/sankey-diagram.component';
import { MonitoringComponent } from './monitoring/monitoring.component';
import { DashboardManagementComponent } from './monitoring/dashboard-management/dashboard-management.component';
import { DashboardListComponent } from './dashboard/dashboard-list/dashboard-list.component';
import { DeviceTypeComponent } from './device/device-type/device-type.component';
import { DeviceManagementComponent } from './device/device-management/device-management.component';
import { ChannelTypeComponent } from './device/device-type/channel-type/channel-type.component';
import { DeviceChannelComponent } from './device/device-channel/device-channel.component';
import { SignupComponent } from './auth/signup/signup.component';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { FooterComponent } from './common/components/charts/footer/footer.component';
import { NewDataloggerComponent } from './data-loggers/new-datalogger/new-datalogger.component';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';

@NgModule({
  declarations: [
    AppComponent,
    DropdownDirective,
    HeaderComponent,
    AssetGroupsComponent,
    AssetsComponent,
    DashboardComponent,
    MediaObjectRoundPillComponent,
    MediaObjectRoundComponent,
    EventsComponent,
    LineChartComponent,
    AuthComponent,
    LoadingSpinnerComponent,
    NotificationComponent,
    RangeFooterComponent,
    YearComponentComponent,
    MonthComponentComponent,
    FulldateComponentComponent,
    MainNavComponent,
    MissionControllerComponent,
    HangarControllerComponent,
    DroneControllerComponent,
    StatusComponent,
    ControlsComponent,
    ConfigComponent,
    HistoryComponent,
    MissionComponent,
    DialogPopupComponent,
    AdminDashboardComponent,
    ManagementComponent,
    ManagementPopupComponent,
    DataLoggersComponent,
    UsersComponent,
    AlertPopupComponent,
    DeviceComponent,
    AssetDevicesComponent,
    SelectPopupComponent,
    UserAssetComponent,
    EditPopUpComponent,
    AddNewComponent,
    ThermographyComponent,
    DroneMapComponent,
    ImageViewComponent,
    ThermographyEventComponent,
    TelemetryEventComponent,
    PieChartComponent,
    GeoChartComponent,
    ScatterChartComponent,
    HistogramComponent,
    AreaChartComponent,
    ComboChartComponent,
    BubbleChartComponent,
    TimelineComponent,
    CandlestickChartComponent,
    TableComponent,
    HeatMapsComponent,
    SankeyDiagramComponent,
    MonitoringComponent,
    DashboardManagementComponent,
    DashboardListComponent,
    DeviceTypeComponent,
    DeviceManagementComponent,
    ChannelTypeComponent,
    DeviceChannelComponent,
    SignupComponent,
    FooterComponent,
    NewDataloggerComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GoogleChartsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatRadioModule,
    RouterModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    SatDatepickerModule,
    SatNativeDateModule,
    NgbModule,
    MatSidenavModule,
    LayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    NgCircleProgressModule.forRoot({
     }),
     AgmCoreModule.forRoot({
      apiKey: ''
    }),
    AngularResizedEventModule,
    MatSelectCountryModule
  ],
  exports: [
    RangeFooterComponent,
    DialogPopupComponent,
    ManagementPopupComponent,
    SelectPopupComponent,
    EditPopUpComponent,
    AlertPopupComponent],
  providers: [
    AuthGuardService,
    AssetGroupsComponent, {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogPopupComponent,
    ManagementPopupComponent,
    SelectPopupComponent,
    EditPopUpComponent,
    AlertPopupComponent]
})
export class AppModule { }
