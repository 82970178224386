import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

  export class DataService {
    $theDate = new EventEmitter();
    $dateRange = new EventEmitter();

    $fromDate = new EventEmitter();
    $toDate = new EventEmitter();
    $clicked = new EventEmitter();

    constructor() { }

    changeDate(dateArray: Date) {
      this.$theDate.emit(dateArray);
    }

    click(clicked: boolean) {
      this.$clicked.emit(clicked);
    }

    fromDate(fromDate: Date) {
      this.$fromDate.emit(fromDate);
    }

    toDate(toDate: Date) {
      this.$toDate.emit(toDate);
    }
  }
