import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.css']
})

export class DialogPopupComponent implements OnInit {
  form: FormGroup;
  description: string;
  timeArray: string[];
  timeInput: number;
  timeInput2: string;
  valuesArray: string[] = ['day', 'month', 'Year'];
  singleSelect: string;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DialogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.description = data.singleSelect;
  }

  ngOnInit() {
    this.form = this.fb.group({
      description: [this.description, []]});
  }

  save() {
    this.data = [this.timeInput, this.singleSelect];
    this.dialogRef.close(this.data);
  }

  close() {
    this.dialogRef.close();
  }
}
