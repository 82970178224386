import { Component, OnInit } from '@angular/core';
import { Mission } from 'src/app/models/mission';
import { ActivatedRoute, Router } from '@angular/router';
import { ThessalyAPIService } from 'src/app/services/thessaly-api.service';
import { ContentObserver } from '@angular/cdk/observers';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  history = [
    { MissionName: 'Mission 1 Default event', StartTime: '1/1/1', FinishTime: '2/3/4/4', StartEvent: 'Timer'},
    { MissionName: 'Mission 2 User', StartTime: '1/12/1', FinishTime: '2/3/4', StartEvent: 'SolarMonitor'},
  ];
  historu: Mission[] = [];
  errorMessage: string;
  constructor(
    private thessalyApiService: ThessalyAPIService,
    private routeActive: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    // this.getMissionHistory();
  }

  getMissionHistory() {
    this.thessalyApiService.controllerGetMission('GetStoredMissions')
    .subscribe(
      response => {
        this.historu.push(response);
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  openThermography(missionName: string) {
    this.router.navigate(['../' + missionName + '/thermography'], { relativeTo: this.routeActive});
  }
}
