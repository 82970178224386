import { EventEmitter, Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree
  } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
  })

  export class isAdminService implements CanActivate{
    $currentRole = new EventEmitter();
    constructor(
      public authService: AuthService,
      private router: Router) { }
    
    canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
      if(this.isAdmin()){
        return true;
      } else {
        this.authService.logout();
        return this.router.createUrlTree(['./login']);
      }
    }

    isAdmin() {
      let isLoggedIn;
      let role = false;
      let decoded;
      try {
        const token: Token = JSON.parse(localStorage.getItem('token')).token;
        decoded = jwt_decode(token);
        isLoggedIn = true;
      } catch (error) {
        isLoggedIn = false;
        role = false;
      }
      if (isLoggedIn) {
        if (decoded.roles.includes('ADMIN')) {
          role = true;
      }
      this.$currentRole.next(role);
      return role;
      }
    }
  }