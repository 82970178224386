import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

  export class LocationService {
    $currentLatitude = new EventEmitter();
    $currentLongtitude = new EventEmitter();
    $homeLatitude = new EventEmitter();
    $homeLongtitude = new EventEmitter();

    constructor() { }

    coordinates(currentLatitude: string, currentLongtitude: string, homeLatitude: string, homeLongtitude: string) {
        this.$currentLatitude.emit(currentLatitude);
        this.$currentLongtitude.emit(currentLongtitude);
        this.$homeLatitude.emit(homeLatitude);
        this.$homeLongtitude.emit(homeLongtitude);
    }
 }