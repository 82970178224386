import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment, { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material';
import { DataService } from 'src/app/services/data-service';

@Component({
  selector: 'app-fulldate-component',
  templateUrl: './fulldate-component.component.html',
  styleUrls: ['./fulldate-component.component.css']
})
export class FulldateComponentComponent implements OnInit {
  @Input() From: number;
  appEndDate: Date;
  minDate: Date;
  maxDate: Date;
  currentYear: never;
  dateArray: number[];
  showValue;
  fromDate: Date;
  toDate: Date;
  today: Date;

  constructor(private service: DataService) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    this.minDate = new Date(currentYear - 20, 0, 0);
    this.maxDate = new Date(currentYear, currentMonth, currentDay);
    this.dateArray = [currentYear, currentMonth, currentDay];
    this.appEndDate = new Date(currentYear, currentMonth, currentDay);
    this.showValue = new FormControl(new Date(currentYear, currentMonth, currentDay));
   }

   ngOnInit() {
    this.findOffset();
  }

  addEvent(event: MatDatepickerInputEvent<Moment>) {
    const eventValue = event.value;
    const month = eventValue.month();
    const year = eventValue.year();
    const day = eventValue.date();
    this.dateArray = [year, month, day];
    this.appEndDate = new Date(this.dateArray[0], this.dateArray[1], this.dateArray[2]);
    this.service.changeDate(this.appEndDate);
    this.findOffset();
  }

  previousDate() {
    if (this.appEndDate.getTime() === this.minDate.getTime()) {
    } else {
      this.appEndDate.setDate(this.appEndDate.getDate() - 1);
      this.dateArray[2] = this.dateArray[2] - 1;
      this.service.changeDate(this.appEndDate);
      this.findOffset();
    }
    this.showValue = new FormControl(new Date(this.dateArray[0], this.dateArray[1], this.dateArray[2]));
  }

  nextDate() {
    if (this.appEndDate.getTime() === this.maxDate.getTime()) {
    } else {
      this.appEndDate.setDate(this.appEndDate.getDate() + 1);
      this.dateArray[2] = this.dateArray[2] + 1;
      this.service.changeDate(this.appEndDate);
      this.findOffset();
    }
    this.showValue = new FormControl(new Date(this.dateArray[0], this.dateArray[1], this.dateArray[2]));
  }

  findOffset() {
    if (this.From === 0) {
      this.fromDate = this.appEndDate;
      this.service.fromDate(this.fromDate);
    } else if(this.From === 1) {
      this.toDate = this.appEndDate;
      this.service.toDate(this.toDate)
    }
    // this.service.fromToDate(this.fromDate, this.toDate);
  }
}
