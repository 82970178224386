import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-mission-controller',
  templateUrl: './mission-controller.component.html',
  styleUrls: ['./mission-controller.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class MissionControllerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
