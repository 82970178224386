import {Component, Input} from '@angular/core';

import {default as _rollupMoment, Moment} from 'moment';
import * as _moment from 'moment';
import { DataService } from 'src/app/services/data-service';

@Component({
  selector: 'app-range-footer',
  templateUrl: './range-footer.component.html',
  styleUrls: ['./range-footer.component.css'],
})
export class RangeFooterComponent {
  @Input() childMessage: string;
  clicked = false;
  buttonImg = 'assets/images/plus.svg';

  constructor(private service: DataService) { }

  ngOnInit() {
  }

  onChangeButton() {
    this.clicked = !this.clicked;
    this.service.click(this.clicked);
    if (this.clicked === false) {
      this.buttonImg = 'assets/images/plus.svg';
    } else if (this.clicked === true) {
      this.buttonImg = 'assets/images/delete.svg';
    }
  }
}