import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Token } from '../rest/model/token';
import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(public authService: AuthService){}
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: Token = JSON.parse(localStorage.getItem('token'));

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token.token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        // return next.handle(request).pipe(
        //     map((event: HttpEvent<any>) => {
        //         console.log(event);
        //         return event;
        //     },(error: any) =>{
        //         alert('eeeeee file');
        //         if (error instanceof HttpErrorResponse) {
        //             if (error.status === 401) {
        //                 this.authService.logout();
        //             }
        //           }
        //     }));
        return next.handle(request)
            .pipe(
            catchError(this.handleError)
            );

    }

    handleError(error: HttpErrorResponse){
        let errorMessage;
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = error;
        }
        return throwError(errorMessage);
    }
}