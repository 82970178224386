import { Component, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HangarStatus } from 'src/app/models/hangarStatus';
import { ThessalyAPIService } from 'src/app/services/thessaly-api.service';

@Component({
  selector: 'app-hangar-controller',
  templateUrl: './hangar-controller.component.html',
  styleUrls: ['./hangar-controller.component.css']
})
export class HangarControllerComponent implements OnInit {
  errorMessage: string;
  hangarStatusResponse: HangarStatus;
  imageUrl = 'assets/images/';
  hatchImageUrl = 'assets/images/loading.svg';
  dockingImageUrl = 'assets/images/loading.svg';
  chargingImageUrl = 'assets/images/loading.svg';

  constructor(
    private thessalyApiService: ThessalyAPIService) { }

  ngOnInit() {
    // this.getHangarStatus();
  }

  getHangarStatus() {
    this.thessalyApiService.controllerHangarGetState()
    .subscribe(
      response => {
        this.hangarStatusResponse = response;
        console.log(response);
        this.getImages();
      },
      error => {
        this.errorMessage = error;
      }
    );
  }

  getImages() {

    if (this.hangarStatusResponse.Hatch === 'OPEN') {
      this.hatchImageUrl = this.imageUrl + 'opened.svg';
    } else if (this.hangarStatusResponse.Hatch === 'CLOSE') {
      this.hatchImageUrl = this.imageUrl + 'close.svg';
    } else if (this.hangarStatusResponse.Hatch === 'OPENING') {
      this.hatchImageUrl = this.imageUrl + 'opening.svg';
    } else if (this.hangarStatusResponse.Hatch === 'CLOSING') {
      this.hatchImageUrl = this.imageUrl + 'closing.svg';
    } else {
      console.log('I dont get right response');
    }

    if (this.hangarStatusResponse.Docking === 'DOCKED') {
      this.dockingImageUrl = this.imageUrl + 'TopDocking.svg';
    } else if (this.hangarStatusResponse.Docking === 'NOTDOCKED') {
      this.dockingImageUrl = this.imageUrl + 'Bottom.svg';
    } else {
      console.log('I dont get right response');
    }

    if (this.hangarStatusResponse.Charging === 'True') {
      this.chargingImageUrl = this.imageUrl + 'battery-charging.svg';
    }  else {
      this.chargingImageUrl = this.imageUrl + 'battery.svg';
    }
  }
}
