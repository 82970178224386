import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isAdminService } from 'src/app/auth/isAdmin.service';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent {
  isAdmin: boolean = false;
  private tokenSub: Subscription;
  isAuthenticated = false;
  private displayHeader = 'Asset Groups';

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  routerOutletComponent: any;
  smallScreenName: string;
  routerOutletComponentClassName: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private isAdminService: isAdminService,
    public router: Router ) {}

  ngOnInit() {
    this.isAdminService.$currentRole.subscribe(role => {
      this.isAdmin = role;
    });
    this.tokenSub = this.authService.token.subscribe(token => {
      this.isAuthenticated = !!token;
    });
    // this.isAdmin = this.isAdminService.isAdmin();
  }

  ngOnDestroy() {
    this.tokenSub.unsubscribe();
  }

  onLogout() {
    this.authService.logout();
  }

  navTo(endPath: string) {
    const path = this.router.routerState.snapshot.root.firstChild.url[0].path + '/'
        + this.router.routerState.snapshot.root.firstChild.url[1].path + '/'
        + this.router.routerState.snapshot.root.firstChild.url[2].path + '/'
        + this.router.routerState.snapshot.root.firstChild.url[3].path + '/';
    this.router.navigate([path + endPath]);
  }

  navToAssetGroup() {
    this.router.navigate(['/home']);
  }

  navToAssets() {
    this.displayHeader = 'Asset';
    if (this.router.url.includes('asset')) {
      const path = this.router.routerState.snapshot.root.firstChild.url[0].path + '/'
        + this.router.routerState.snapshot.root.firstChild.url[1].path;
      this.router.navigate([path]);
    }
  }

  onActivate(event) {
    this.routerOutletComponent = event;
    this.routerOutletComponentClassName = event.constructor.name;
    switch(this.routerOutletComponentClassName) {
      case 'AssetDevicesComponent':
        this.smallScreenName = 'Asset Devices';
        break;
      case 'AssetsComponent':
        this.smallScreenName = 'Asset';
        break;
      case 'UserAssetComponent':
        this.smallScreenName = 'User Asset';
        break;
      case 'DashboardListComponent':
        this.smallScreenName = 'Dashboard List';
        break;
      case 'MonitoringComponent':
        this.smallScreenName = 'Monitoring';
        break;
      case 'DeviceChannelComponent':
        this.smallScreenName = 'Device Channel';
        break;
      case 'ChannelTypeComponent':
        this.smallScreenName = 'Channel Type';
        break;
      case 'DeviceTypeComponent':
        this.smallScreenName = 'Device Type';
        break;
      case 'DeviceComponent':
        this.smallScreenName = 'Device';
        break;
      case 'DeviceManagementComponent':
        this.smallScreenName = 'Device Management';
        break;
      case 'AssetGroupsComponent':
        this.smallScreenName = 'Asset Group';
        break;
      case 'AdminDashboardComponent':
        this.smallScreenName = 'Admin Dashboard';
        break;
      case 'DataLoggersComponent':
        this.smallScreenName = 'Dataloggers';
        break;
      case 'UsersComponent':
        this.smallScreenName = 'Users';
        break;
      case 'AuthComponent':
        this.smallScreenName = 'Log In';
        break;
      default:
        this.smallScreenName = 'Sign Up';
        break;
    }
  }
}
