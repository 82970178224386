import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import {default as _rollupMoment, Moment} from 'moment';
import * as _moment from 'moment';
import { DataService } from 'src/app/services/data-service';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-component',
  templateUrl: './month-component.component.html',
  styleUrls: ['./month-component.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class MonthComponentComponent implements OnInit {
  date = new FormControl(moment());
  minDate: Date;
  maxDate: Date;
  minArray: number[];
  maxArray: number[];
  constructor(private service: DataService) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    this.minDate = new Date(currentYear - 20, 0, 0);
    this.maxDate = new Date(currentYear, currentMonth, currentDay);
    this.minArray = [this.minDate.getMonth(), this.minDate.getFullYear()];
    this.maxArray = [currentMonth, currentYear];
    console.log(this.maxArray);
  }

  ngOnInit() {
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    const month = normalizedMonth.month();
    const year = normalizedMonth.year();
  }

  previousDate() {
    let ctrlValue = this.date.value;
    if (this.minArray[1] === ctrlValue.year() && this.minArray[0] === ctrlValue.month()) {
      console.log('den kns tpt');
    } else {
      ctrlValue.month(ctrlValue.month() - 1);
      this.date.setValue(ctrlValue);
      const appEndDate = new Date(this.date.value.year(), this.date.value.month(), 1);
      this.service.changeDate(appEndDate);
    }
  }
  
  nextDate() {
    let ctrlValue = this.date.value;
    if (this.maxArray[1] === ctrlValue.year() && this.maxArray[0] === ctrlValue.month()) {
      console.log('den kns tpt');
    } else {
      ctrlValue.month(ctrlValue.month() + 1);
      this.date.setValue(ctrlValue);
      const appEndDate = new Date(this.date.value.year(), this.date.value.month(), 1);
      this.service.changeDate(appEndDate);
    }
  }
}