import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DialogPopupComponent } from 'src/app/mission-controller/mission/dialog-popup/dialog-popup.component';
import { Asset, ChannelType, ChannelTypeExcludingIdDeviceTypeId, ChannelTypeExcludingNameDescriptionUnitChannelTypeDeviceTypeId, DeviceType } from 'src/app/rest';
export interface SavedElement {
  name: string;
  description: string;
}

export interface SavedDashboard {
    assetName: Asset;
    deviceCategory: DeviceType;
    channelCategory: string;
    chartType: string;
    timeWindow: number;
    operation: string;
    groupBy: string;
    title: string;
    xLabel: string;
    yLabel: string;
}

@Component({
  selector: 'app-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.css']
})
export class AddNewComponent implements OnInit {
  @Input() allAssets: Asset[];
  @Input() fromType: string = 'type';
  @Input() allCategories: DeviceType[];
  @Input() fromComponent: string;
  @Input() allChannelCategories: string[];
  @Input() element: {title: string, placeholder1: string, placeholder2: string};
  @Output() saveThis = new EventEmitter<SavedElement>();
  @Output() selectedAssetID = new EventEmitter<Asset>();
  @Output() selectedDeviceCategory = new EventEmitter<DeviceType>();
  @Output() saveDashboard = new EventEmitter<SavedDashboard>();
  savedElement: { name: string; description: string; };
  savedDashboard: {
    assetName: Asset;
    deviceCategory: DeviceType;
    channelCategory: string;
    chartType: string;
    timeWindow: number;
    operation: string;
    groupBy: string;
    title: string;
    xLabel: string;
    yLabel: string; };

  selectedAssetValue: Asset;
  selectedDeviceCategoryValue: DeviceType;
  selectedChannelCategoryValue: string;
  selectedChartType: string;
  selectedTime: number;
  selectedOperation: string;
  selectedGroup: string;
  assetGroupDescription: string;
  assetGroupName: string;
  title: string;
  xLabel: string;
  yLabel: string;
  // selectedAssetValue = selectedDeviceCategoryValue =
  //  selectedChannelCategoryValue = selectedChartType = selectedTime = selectedOperation = selectedGroup = '';
  // assetGroupDescription = assetGroupName = assetName
  // = deviceCategory = channelCategory = chartType = operation = timeWindow = groupBy = title = xLabel = yLabel = '';
  deviceCategories = ['Category 1', 'Category 2', 'Category 3'];
  channelCategories = ['Category 1', 'Category 2', 'Category 3', 'Category 0'];
  // chartTypes = ['lineChart', 'areaChart', 'bubleChart', 'candlestickChart', 'comboChart', 'geoChart', 'heatMap',
  //   'histogram', 'pieChart', 'sankeyMap', 'scatterChart', 'timeline'];
  chartTypes = ['areaChart', 'lineChart', 'pieChart', 'scatterChart'];
  timeWindowArray = ['day', 'month', 'Year'];
  operationArray = ['sum', 'average', 'min', 'max', 'none'];
  groupArray = ['day', 'month', 'year', 'none'];

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  OnChanges() {
    console.log(this.selectedChannelCategoryValue);
  }

  openDialog(from: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.width = '250px';
    // dialogConfig.height = '250px';
    dialogConfig.autoFocus = true;
    dialogConfig.data = { value: 'den lew'};
    const dialogRef = this.dialog.open(DialogPopupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (typeof result !== 'undefined') {
        if (result[1] == 'day') {
          console.log(result)
          this.selectedTime = 86400 * result[0];
        } else if (result[1] == 'month') {
          this.selectedTime = 2592000 * result[0];
        } else {
          this.selectedTime = 31536000 * result[0];
        }
      }
      this.sendToSave(from);
    });
   }

  callParentSave(from: string) {
    if (from === 'newDashboard') {
      this.openDialog(from);
    } else {
      this.savedElement = {name: this.assetGroupName, description: this.assetGroupDescription};
      this.saveThis.next(this.savedElement);
    }
    
  }

  sendToSave(from: string) {
    if (from === 'newDashboard') {
      this.savedDashboard = {assetName: this.selectedAssetValue, deviceCategory: this.selectedDeviceCategoryValue,
         channelCategory: this.selectedChannelCategoryValue,
         chartType: this.selectedChartType, timeWindow: this.selectedTime, operation: this.selectedOperation,
         groupBy: this.selectedGroup, title: this.title, xLabel: this.xLabel, yLabel:  this.yLabel};
      this.saveDashboard.next(this.savedDashboard);
    }
  }

  onChangeAsset(asset) {
    this.selectedAssetValue = asset;
    this.selectedAssetID.emit(asset);
  }

  onChangeDeviceCategory(deviceCategory) {
    this.selectedDeviceCategoryValue = deviceCategory;
    this.selectedDeviceCategory.emit(deviceCategory);
  }

  onChangeChannelCategory(channelCategory) {
    this.selectedChannelCategoryValue = channelCategory;
  }

  onChangeChartType(chartType) {
    this.selectedChartType = chartType;
  }

  onChangeOperation(operation) {
    this.selectedOperation = operation;
  }

  onChangeGroup(groupBy) {
    this.selectedGroup = groupBy;
  }
}
