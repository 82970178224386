import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {
  
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }
  open: boolean = false;
  
  @HostListener('click') toggleOpen() {
    console.log(this.elRef.nativeElement);
    if(!this.open) {
      this.renderer.addClass(this.elRef.nativeElement,'show');
    }
    else {
      this.renderer.removeClass(this.elRef.nativeElement,'show');
    }
    
    this.open = !this.open;
  }
}