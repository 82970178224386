export * from './assetController.service';
import { AssetControllerService } from './assetController.service';
export * from './assetGroupController.service';
import { AssetGroupControllerService } from './assetGroupController.service';
export * from './dashboardElementController.service';
import { DashboardElementControllerService } from './dashboardElementController.service';
export * from './dataLoggerController.service';
import { DataLoggerControllerService } from './dataLoggerController.service';
export * from './deviceChannelController.service';
import { DeviceChannelControllerService } from './deviceChannelController.service';
export * from './deviceController.service';
import { DeviceControllerService } from './deviceController.service';
export * from './deviceEventController.service';
import { DeviceEventControllerService } from './deviceEventController.service';
export * from './deviceGroupMembershipController.service';
import { DeviceGroupMembershipControllerService } from './deviceGroupMembershipController.service';
export * from './deviceTypeChannelTypeController.service';
import { DeviceTypeChannelTypeControllerService } from './deviceTypeChannelTypeController.service';
export * from './deviceTypeController.service';
import { DeviceTypeControllerService } from './deviceTypeController.service';
export * from './eventController.service';
import { EventControllerService } from './eventController.service';
export * from './pingController.service';
import { PingControllerService } from './pingController.service';
export * from './schedulerController.service';
import { SchedulerControllerService } from './schedulerController.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './userSubscriptionController.service';
import { UserSubscriptionControllerService } from './userSubscriptionController.service';
export * from './virtualAccessController.service';
import { VirtualAccessControllerService } from './virtualAccessController.service';
export const APIS = [AssetControllerService, AssetGroupControllerService, DashboardElementControllerService, DataLoggerControllerService, DeviceChannelControllerService, DeviceControllerService, DeviceEventControllerService, DeviceGroupMembershipControllerService, DeviceTypeChannelTypeControllerService, DeviceTypeControllerService, EventControllerService, PingControllerService, SchedulerControllerService, UserControllerService, UserSubscriptionControllerService, VirtualAccessControllerService];
