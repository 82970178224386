import { Component, OnInit } from '@angular/core';
import { DashboardElementControllerService, DashboardElementWithScope, DeviceChannelControllerService, ResponseDeviceChannelData } from 'src/app/rest';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import jwt_decode from 'jwt-decode';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  serviceError = false;
  loading = true;
  chartTitle:  string;

  currentUserId: number;
  allDashboards: DashboardElementWithScope[];
  constructor(
    private dashboardElementControllerService: DashboardElementControllerService,
    private router: ActivatedRoute,

    ) { }

  ngOnInit() {
    this.getCurrentUser();
    this.getDashboard();
  }

  getDashboard() {
    this.allDashboards = [];
    const assetId = +this.router.snapshot.paramMap.get('id');
    this.dashboardElementControllerService.dashboardElementControllerFindByUserIdAssetIdLocation(assetId, this.currentUserId, 'assetDashboard')
    .subscribe(
      response => {
        this.allDashboards.push(...response);
      },
      errorRes => {
        if (!errorRes.error || !errorRes.error.error || !errorRes.error.error.statusCode) {
          this.serviceError = true;
          this.loading = false;
          return;
        }
        switch(parseInt(errorRes.error.error.statusCode)) {
          case 401:
            this.loading = false;
            // this.authService.logout();
          case 406:
            this.serviceError = true;
            this.loading = false;
        }
      });
  }

  getCurrentUser() {
    const token: Token = JSON.parse(localStorage.getItem('token')).token;
    const decoded = jwt_decode(token);
    this.currentUserId = decoded.id;
  }
}
